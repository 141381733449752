import Lib from "../Lib";

let admin = {
  GET: (path, query) => Lib.makeRequest(path, "GET", null, query),
  GETBYID: (path, query, id) => Lib.makeRequest(`${path}/detail/${id}`, "GET", null, query),
  PATCH: (path, body, query, id) => Lib.makeRequest(`${path}/${id}`, "PATCH", body, query),
  POST: (path, body, query) => Lib.makeRequest(`${path}/`, "POST", body, query),
  DELETE: (path, query) => Lib.makeRequest(`${path}/`, "DELETE", null, query),
};
export default admin;
