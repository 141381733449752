/* index Lib */
import makeRequest from './makeRequest';
import windowOrigin from'./windowOrigin';
import verifyEmail from "./verifEmail";

const Lib = {
    verifyEmail: (emailStr) => verifyEmail(emailStr),
    makeRequest: (path, method, body, query) => makeRequest(path, method, body, query),
    windowOrigin: () => windowOrigin(),
};
export default Lib;