import {ReactNotifications, Store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

/**
 *
 * @param {string} title
 * @param {string} message
 * @param {string} type success|danger|info|default|warning
 * @param {number} duration
 * @return {*} notification id need to remove it (`store.removeNotification(id)`)
 */
function createNotification(title = ' ', {message = ' ', type = 'success', duration = 5000} = {}) {
  return Store.addNotification({
    title, message, type,
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
      pauseOnHover: true,
    }
  });
}

export {ReactNotifications, createNotification};
