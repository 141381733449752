import React  from "react";
import './styles.scss';
import { Link } from "react-router-dom";

const ClubSelect = ({club, selectClub}) => {
    return (<>
      <td className={ 'selectorClub' }>
        <input type={'checkbox'} name={club._id} onChange={ selectClub }/>
      </td>
      <td className={ 'createdAtClub' }><Link to={'/club/detail?id='+club._id}>{ new Date(club.createdAt).toDateString() }</Link></td>
      <td className={ 'updatedAtClub' }>
        {club.updatedAt.length > 0 && <Link to={'/club/detail?id='+club._id}>{ new Date(club.updatedAt).toDateString() }</Link> }</td>
      <td className={ 'typeOfClub' }><Link to={'/club/detail?id='+club._id}>{ club.typeOfClub }</Link></td>
      <td className={ 'usernameClub' }><Link to={'/club/detail?id='+club._id}>{ club.username }</Link></td>
      <td className={ 'nameClub' }><Link to={'/club/detail?id='+club._id}>{ club.name }</Link></td>
      <td className={ 'managerNameClub' }><Link to={'/club/detail?id='+club._id}>{ club.managerName }</Link></td>
      <td className={ 'emailClub' }><a href={'mailto:'+ club.email}>{club.email}</a></td>
    </>);
};

export default ClubSelect;