import React, {useEffect, useRef, useState} from 'react';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const ControlledJsonEditor = ({ value, mode, allowedModes, ...props }) => {
  const jsonEditorRef = useRef();
  const [isLoading, setLoading] = useState(false); // trick to fix not reactive props allowedModes of JsonEditor

  useEffect(() => {
    if (value) {
      jsonEditorRef?.current?.jsonEditor?.update?.(value);
    }
  }, [value])

  useEffect(() => {
    setLoading(true)
  }, [mode, allowedModes])

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }
  }, [isLoading])

  if(isLoading){
    return null;
  }

  return (
    <JsonEditor
      {...props}
      ref={jsonEditorRef}
      language="fr-FR"
      value={value}
      mode={mode}
      allowedModes={allowedModes}
      history
      search={false}
      enableSort={false}
      enableTransform={false}
    />
  );
}

export default ControlledJsonEditor;
