import React, {useState} from "react";
import './styles.scss';
import ScreenComponentsEditor from "src/components/ClubMobileScreenCustomView/ScreenComponentsEditor";

const _ = {
  selectScreens: [
    {value: 'customHomeTemplate', label: 'Écran d’accueil'},
    {value: 'customTrainingTemplate', label: 'Écran d’entraînement'},
    {value: 'customMenuTemplate', label: 'Écran menu'},
    {value: 'customMemberAreaTemplate', label: 'Espace membre'},
  ],
  selectSections: {
    customMenuTemplate: [
      {value: 'components', label: 'Composants'},
      {value: 'buttonMap', label: 'Buttons'},
    ],
  },
  assiste: {
    customMenuTemplate: {
      buttonMap: {
        AccessCardButton: false,
        BlogButton: false,
        BookingButton: false,
        ClubButton: false,
        CustomFormButton: false,
        ExerciceButton: false,
        FormReportButton: false,
        MemberAreaButton: false,
        PlanningButton: false,
        ProductButton: false,
        ShopButton: false,
        ShopResamaniaButton: false,
        TimerButton: false,
        TrainingButton: false,
        VodButton: false,
        WorkoutButton: false,
        WorkoutPackButton: false,
      },
    },
  },
};

const ClubMobileScreenCustomView = ({club, clubUpdater, editor}) => {
  const [selectedScreen, setSelectedScreen] = useState(_.selectScreens[0].value);
  const [selectedSection, setSelectedSection] = useState('components');

  let screenTemplate = club[selectedScreen];

  const handleSelectedScreenChange = (event) => {
    setSelectedScreen(event.target.value);
    setSelectedSection(_.selectSections[event.target.value]?.[0].value ?? 'components');
  };

  const handleSelectedSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleValuesChange = (values) => {
    const customScreenTemplate = {...screenTemplate};
    customScreenTemplate[selectedSection] = values;
    let tmp = { ...club, [selectedScreen]: customScreenTemplate};
    clubUpdater(tmp);
  }

  const values = screenTemplate?.[selectedSection] ?? (selectedSection === 'components' ? [] : {});
  const assistMap = _.assiste[selectedScreen]?.[selectedSection]
  return (
    <div className="mobileScreen-panel">
      <div className="mobileScreen-select-bar">
        <label className="mobileScreen-label">
          Modèle :
          <select className="mobileScreen-label-select" value={selectedScreen} onChange={handleSelectedScreenChange}>
            {_.selectScreens.map(({value, label}) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </label>
        {!_.selectSections[selectedScreen] ? null : (
          <label className="mobileScreen-label">
            Section :
            <select className="mobileScreen-label-select" value={selectedSection} onChange={handleSelectedSectionChange}>
              {_.selectSections[selectedScreen].map(({value, label}) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </select>
          </label>
        )}
      </div>
      <ScreenComponentsEditor
        components={values} onComponentsChange={handleValuesChange} editor={editor} assistMap={assistMap}
      />
    </div>
  );
};

export default ClubMobileScreenCustomView;
