import windowOrigin from "./windowOrigin";

const makeRequest = (path, method, body, query) => {
  let baseUrl = windowOrigin();
  let token = '';
  document.cookie.split(';').forEach((el) => {
    if ( el.match(/token/i) ) {
      token = el.split('=')[1];
    }
  });
  let headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${ token }`
  };
  return fetch(`${baseUrl}/api/admin/${path}${query ? "?" + query : ''}`, {
    method: method,
    headers: headers,
    body: body && JSON.stringify(body),
  })
    .then((response) => {
      return Promise.all([response, response.json()]);
    })
    .then(([response, body]) => {
      return {
        statusCode: response.status,
        body,
      };
    })
    .catch((err) => console.error(err));
};

export default makeRequest;
