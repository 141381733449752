import React, { useState } from "react";
import Cookies from "universal-cookie";
import { Redirect } from "react-router-dom";
import './styles.scss';
import windowOrigin from '../../Lib/windowOrigin';
import API from '../../api';

const LoginInput = ({ builtFor }) => {
    let origin = windowOrigin();
    let cookie = new Cookies('/');
    let [ email, emailUpdate ] = useState('');
    let [ password, passUpdate ] = useState('');
    let [ remember, rememberUpdate ] = useState( !!cookie.get('remember') );

    if (API.iAmAuth()) {
        return (<Redirect to={{
            pathname: "/"
        }} />)
    }

    const handleSubmit = () => {
        API.signIn({email, password}).then((data) => {
            if (data.statusCode === 401) {
                alert(data.body);
            } else if (data.statusCode === 200) {
                let reponse = data.body;
                cookie.set('token', reponse.token, { path: '/', maxAge: 604800 });
                if (remember) {
                    cookie.set('remember', true, { path: '/' });
                    cookie.set('refreshToken', reponse.refreshToken, { path: '/', maxAge: 604800 * 2 });
                }
                window.location.assign(window.location.href = origin);
            }
        });
    };

    const handleChange = (event) => {
        if (event.target.name === `email-${builtFor}`) {
            emailUpdate(event.target.value);
        } else if (event.target.name === `password-${builtFor}`) {
            passUpdate(event.target.value);
        } else if (event.target.name === 'remember') {
            rememberUpdate(!remember);
        }
    };

    return (<>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-email">Email</span>
            </div>
            <input
                className={ 'form-control' }
                type='email'
                defaultValue={ email }
                onChange={ handleChange }
                name={`email-${builtFor}`}
                aria-describedby="emailHelpBlock"
                placeholder={"example@email.com"} />

        </div>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-password">Password</span>
            </div>
            <input
                className={ 'form-control' }
                type='password'
                defaultValue={ password }
                onChange={ handleChange }
                name={ `password-${builtFor}` }
                placeholder={ "Password" } />
        </div>

        { builtFor === 'signin' &&
        <div className="form-check">
            <input
                className={ 'form-check-input' }
                type={'checkbox'}
                defaultChecked={ remember }
                name={ 'remember' }
                onChange={ handleChange }
            />
            <label className="form-check-label" htmlFor="exampleCheck1">Remember me !</label>
        </div>
        }
        <button
            className={ 'btn btn-primary' }
            onClick={ handleSubmit }
        >{ builtFor === 'signin' ? "Connect" : "Create my Account" }</button>
    </>);
};

export default LoginInput;
