import React  from "react";
import './styles.scss';

const ClubTextView = ({club, clubUpdater, editor}) => {

  const handleChange = (ev) => {
    let text = { ...club.text };
    text[ev.target.name] = ev.target.value;
    clubUpdater({ ...club, text: { ...text } });
  };

  return (
      <div className={ 'mainTextContainer' }>
        { club.text && <>
          <div className={ 'textContainer' }>

            <div className="form-group">
              <label htmlFor="clientAttachmentmessageInput">Client Attachment Message</label>
              <input type="text" className="form-control" id="clientAttachmentmessageInput" onChange={ handleChange }
                     defaultValue={ club.text.clientAttachmentMessage || '' } name={ 'clientAttachmentMessage' }
                     aria-describedby="Attachment Message" placeholder="Attachment Message" disabled={ editor.state }/>
            </div>

            <div className="form-group">
              <label htmlFor="mottoInput">Motto</label>
              <input type="text" className="form-control" id="mottoInput"
                     defaultValue={ club.text.motto } name={ 'motto' } onChange={ handleChange }
                     aria-describedby="Motto of club" placeholder="Motto" disabled={ editor.state }/>
            </div>

            <div className="form-group">
              <label htmlFor="NotesInput">Notes</label>
              <input type="text" className="form-control" id="NotesInput"
                     defaultValue={ club.text.notes } name={ 'notes' } onChange={ handleChange }
                     aria-describedby="Notes about club" placeholder="Notes about club" disabled={ editor.state }/>
            </div>

            <div className="form-group">
              <label htmlFor="notificationDefaultInput">Notification Default</label>
              <input type="text" className="form-control" id="notificationDefaultInput" disabled={ editor.state }
                     defaultValue={ club.text.notificationDefault } name={ 'notificationDefault' } onChange={ handleChange }
                     aria-describedby="Notification default text for club" placeholder="Notification default text" />
            </div>

          </div>
          <div className={ 'textContainer' }>

            <div className="form-group">
              <label htmlFor="referralHeaderInput">Referral Header</label>
              <input type="text" className="form-control" id="referralHeaderInput"
                     defaultValue={ club.text.referralHeader } name={ 'referralHeader' } onChange={ handleChange }
                     aria-describedby="Referral Header of club" placeholder="Referral Header" disabled={ editor.state }/>
            </div>

            <div className="form-group">
              <label htmlFor="sponsorInviteMessageInput">Sponsor Invite Message</label>
              <textarea id="sponsorInviteMessageInput" value={ club.text.sponsorInviteMessage } name={ 'sponsorInviteMessage' } disabled={ editor.state }
                        onChange={ handleChange } className="form-control"/>
              {/*<input type="text" className="form-control" id="sponsorInviteMessageInput" disabled={ editor.state }*/}
              {/*       defaultValue={ club.text.sponsorInviteMessage } name={ 'sponsorInviteMessage' } onChange={ handleChange }*/}
              {/*       aria-describedby="Sponsor Invite Message of club" placeholder="Sponsor Invite Message of club" />*/}
            </div>

            <div className="form-group">
              <label htmlFor="welcomeEmailSubjectInput">Welcome Email Subject</label>
              <input type="text" className="form-control" id="welcomeEmailSubjectInput" disabled={ editor.state }
                     defaultValue={ club.text.welcomeEmailSubject } name={ 'welcomeEmailSubject' } onChange={ handleChange }
                     aria-describedby="Welcome Email Subject of club" placeholder="Welcome Email Subject" />
            </div>

            <div className="form-group">
              <label htmlFor="welcomeEmailInput">welcomeEmail</label>
              <textarea id="welcomeEmailInput" value={ club.text.welcomeEmail } name={ 'welcomeEmail' } disabled={ editor.state }
                        onChange={ handleChange } className="form-control"/>
              {/*<input type="text" className="form-control" id="welcomeEmailInput" disabled={ editor.state }*/}
              {/*       defaultValue={ club.text.welcomeEmail } name={ 'welcomeEmail' } onChange={ handleChange }*/}
              {/*       aria-describedby="Welcome Email Message of club" placeholder="Welcome Email Message" />*/}
            </div>

            <div className="form-group">
              <label htmlFor="sponsor_detailInput">sponsor_detail</label>
              <textarea id="sponsor_detailInput" value={ club.text.sponsor_detail } name={ 'sponsor_detail' } disabled={ editor.state }
                        onChange={ handleChange } className="form-control"/>
            </div>

          </div>
        </>}
      </div>
  );
};

export default ClubTextView;
