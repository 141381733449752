import React, { useState } from "react";
import './styles.scss';
import ClubChargebeeView from 'src/components/ClubChargebeeView/ClubChargebeeView';

import API from "../../api";
import ClubGeneralView from "../../components/ClubGeneralView";
import ClubTextView from "../../components/ClubTextView";
import ClubPicturesView from "../../components/ClubPicturesView";
import ClubBookingView from "../../components/ClubBookingView";
import ClubStatusView from "../../components/ClubStatusView";
import ClubCompaniesView from "../../components/ClubCompaniesView";
import ClubResamaniaView from "../../components/ClubResamaniaView";
import ClubMobileCustomView from "../../components/ClubMobileCustomView";
import ClubMobileHomeCustomView from "../../components/ClubMobileScreenCustomView";

const ClubDetail = () => {
    let [ load, loadUpdate ] = useState(false);
    let [ modify, modifyUpdate ] = useState({ state: true, name: 'Éditer', color: 'info'});
    let [ ClubDetail, ClubUpdater ] = useState({});

    const loadData = () => {
        API.detailClub(null, window.location.search.slice(4))
            .then((data) => {
                if (data.statusCode === 200) {
                    let tmp = data.body;
                    ClubDetail = tmp.club;
                    ClubUpdater({ ...ClubDetail });
                }
            }).catch((err) => {
                console.log(err, "error loading club");
            });
    };

    const handleChange = (ev) => {
        if (ev.target.name === 'modify') {
            let nameModify = modify.name === "Éditer" ? "Enregistrer" : "Éditer";
            let colorModify = modify.color === "info" ? "warning" : "info";
            modifyUpdate({ state: !modify.state, name: nameModify, color: colorModify });
            if (!modify.state) {
                API.updateClub('', { ...ClubDetail }, ClubDetail._id).then((ret) => {
                    ClubUpdater(ret.body);
                }).catch((err) => {
                    console.log(err, "error on save Club");
                });
            }
        }
    };

    if (!load) {
        loadData();
        loadUpdate(true);
    }

    return (<>
        <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-general-tab" data-toggle="tab" href="#nav-general" role="tab"
                   aria-controls="nav-general" aria-selected="true">General</a>
                <a className="nav-item nav-link" id="nav-chargebee-tab" data-toggle="tab" href="#nav-chargebee" role="tab"
                   aria-controls="nav-chargebee" aria-selected="false">Chargebee</a>
                <a className="nav-item nav-link" id="nav-text-tab" data-toggle="tab" href="#nav-text" role="tab"
                   aria-controls="nav-text" aria-selected="false">Text</a>
                <a className="nav-item nav-link" id="nav-picture-tab" data-toggle="tab" href="#nav-picture" role="tab"
                   aria-controls="nav-picture" aria-selected="false">Pictures</a>
                <a className="nav-item nav-link" id="nav-booking-tab" data-toggle="tab" href="#nav-booking" role="tab"
                   aria-controls="nav-booking" aria-selected="false">Booking</a>
                <a className="nav-item nav-link" id="nav-state-tab" data-toggle="tab" href="#nav-state" role="tab"
                   aria-controls="nav-state" aria-selected="false">Status</a>
                <a className="nav-item nav-link" id="nav-companies-tab" data-toggle="tab" href="#nav-companies"
                   role="tab" aria-controls="nav-companies" aria-selected="false">Companies</a>
                <a className="nav-item nav-link" id="nav-resamania-tab" data-toggle="tab" href="#nav-resamania" role="tab"
                   aria-controls="nav-resamania" aria-selected="false">Resamania</a>
                <a className="nav-item nav-link" id="nav-mobile-tab" data-toggle="tab" href="#nav-mobile" role="tab"
                   aria-controls="nav-mobile" aria-selected="false">Mobile</a>
                <a className="nav-item nav-link" id="nav-mobileScreen-tab" data-toggle="tab" href="#nav-mobileScreen" role="tab"
                   aria-controls="nav-mobileScreen" aria-selected="false">Mobile Écran</a>
                <div className={ 'buttonEdit' }>
                    <button type="button" className={ `btn btn-${modify.color}`} onClick={ handleChange } name={'modify'}>{ modify.name }</button>
                </div>
                <div className={ 'buttonEdit' }>
                  <a href={`/club/stats?id=${ ClubDetail._id }`} >
                    <button type="button" className={ `btn btn-primary` }>Stats</button>
                  </a>
                </div>
            </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                {ClubDetail.contact &&
                    <ClubGeneralView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-chargebee" role="tabpanel" aria-labelledby="nav-chargebee-tab">
                {/*<ClubTextView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>*/}
                <ClubChargebeeView club={ClubDetail} reloadData={loadData}/>
            </div>
            <div className="tab-pane fade" id="nav-text" role="tabpanel" aria-labelledby="nav-text-tab">
                {ClubDetail.text &&
                    <ClubTextView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-picture" role="tabpanel" aria-labelledby="nav-picture-tab">
                {ClubDetail.pictures &&
                    <ClubPicturesView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-booking" role="tabpanel" aria-labelledby="nav-booking-tab">
                {ClubDetail.booking &&
                    <ClubBookingView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-state" role="tabpanel" aria-labelledby="nav-state-tab">
                {ClubDetail.state &&
                    <ClubStatusView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-companies" role="tabpanel" aria-labelledby="nav-companies-tab">
                {ClubDetail.countRelatedCompanies <= 0 ? 'Ce club n’est pas un groupe' : (
                  <ClubCompaniesView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                )}
            </div>
            <div className="tab-pane fade" id="nav-resamania" role="tabpanel" aria-labelledby="nav-resamania-tab">
                {ClubDetail.resamania &&
                    <ClubResamaniaView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
                }
            </div>
            <div className="tab-pane fade" id="nav-mobile" role="tabpanel" aria-labelledby="nav-mobile-tab">
                <ClubMobileCustomView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
            </div>
            <div className="tab-pane fade" id="nav-mobileScreen" role="tabpanel" aria-labelledby="nav-mobileScreen-tab">
                <ClubMobileHomeCustomView club={ ClubDetail } clubUpdater={ ClubUpdater } editor={ modify }/>
            </div>
        </div>
    </>);
};

export default ClubDetail;
