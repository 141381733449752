import React from "react";
import './styles.scss';
import API from "../../api";

const ActionBarList = ({
    handleChange, search, count, retour,
    retUpdate, type, initialRetour, selectedDocument, clearSelect
}) => {
    const handleSearchChange = (ev) => {
        if (ev.target.name === 'search') {
            handleChange(ev);
        }
    };
    const launchSearch = (ev) => {
        if (ev.key === 'Enter') {
            search();
        }
    };

    const editSelected = (ev) => {
        const perform = (doc) => {
            let randomnumber = Math.floor((Math.random() * 100) + 1);
            window.open(`/${type}/detail?id=${doc}`, "_blank", 'PopUp' + randomnumber + ',scrollbars=1,menubar=0,resizable=1,width=850,height=500');
        };
        for (let i = 0; i < selectedDocument.length; i++) {
            perform(selectedDocument[i]);
        }
    };

    const deleteSelected = (ev) => {
        ev.preventDefault();
        if (selectedDocument.length <= 0) {
            return;
        }
        let p = [];
        let query = "";

        selectedDocument.forEach((el, key) => {
            if (el !== undefined) {
                return query += `id=${el}${key < selectedDocument.length - 1 ? '&':''}`;
            }
        });
        switch (type) {
            case 'documents':
                p.push(API.deleteDocument(query));
                break;
            case 'admin':
                p.push(API.deleteAdmin(query));
                break;
            case 'club':
                // mettre une secu qui rajoute le secure=true a la query sous forme de popup
                query += '&secure=true';
                p.push(API.deleteClub(query));
                break;
            default:
                return ;
        }
        Promise.all(p)
            .then((ret) => {
                let tmp = {};

                if (clearSelect) { clearSelect(); }

                if (ret[0].body) {
                    tmp = ret[0].body;
                }
                if (ret[0].statusCode < 200 && ret[0].statusCode >= 300) {
                    alert('Error Cancel Operation');
                    setTimeout(() => search(''), 500);
                } else if (ret[0].statusCode >= 200 && ret[0].statusCode < 300 && tmp) {
                    alert('Operation Complete');
                    setTimeout(() => search(''), 500);
                }
            });
    };

    const handleCreateClub = () => {
        return window.open('https://pro.masalledesport.com/club/createNew');
    }

    return (
      <div className={"ActionBar"}>
        <input type={'search'} onKeyPress={ launchSearch } onChange={ handleSearchChange } name={'search'} />
        <button onClick={ search } type="button" className="btn btn-secondary">Recherche parmis: { count } {type}</button>
        {type === 'club'
          ? <button type="button" className="btn btn-primary" onClick={handleCreateClub}>Créer</button>
          : <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalCreate">Créer</button>
        }
            <button type="button" className="btn btn-success" onClick={editSelected}>Editer la selection</button>
        <button type="button" className="btn btn-danger" onClick={ deleteSelected }>Supprimer</button>
      </div>
    );
}

export default ActionBarList;
