import React  from "react";
import LoginInput from "../../components/Login";
import './styles.scss';

const Login = () => {
    return (<>
        <div className={ 'mainLogin' }>
            <div className={ 'jumbotron signinContainer' }>
                <h3>Connection</h3>
                <LoginInput builtFor="signin"/>
            </div>
        </div>
    </>);
};

export default Login;
