import React, {useEffect, useState} from 'react';
import CircleLoader from 'react-spinners/CircleLoader';
import TableViewer from 'react-js-table-with-csv-dl';
import API from "../../api";

import "./styles.scss";

function ClubActivity(){
  const [data, setData] = useState(null);

  useEffect(() => {
    API.getClubActivity().then((results) => {
      const resultData = results.body;
      setData(resultData.map((c) => ({...c, lastNotificationDate: c.lastNotificationDate || ''})));
    });
  }, []);

  if (!Array.isArray(data)) {
    return (
      <div className="clubActivityMain">
        <p>Chargement...</p>
        <CircleLoader
          size={300}
          color={"#17a2b8"}
        />
      </div>
    );
  }

  return (
    <div className="clubActivityMain">
      <div className="clubActivityGrid">
        <TableViewer
          headers={['id', 'name', 'notificationCount', 'lastNotificationDate']}
          content={data}
          activateDownloadButton
          sortColumn={'lastNotificationDate'}
        />
      </div>
    </div>
  );
}

export default ClubActivity;
