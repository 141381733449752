import React, { useState } from "react";

import './styles.scss';
import API from "../../api";
import Lib from "../../Lib";

const CreateAdmin = ({club, clubUpdate, initialError, error, errorUpdate}) => {
    const TextField = [ "email", "password", "managerName", "themeColor" ];
    let [ newClub, newClubUpdate ] = useState(club);
    let [ styleColorPick, styleColorPickUpdater ] = useState({ 'background': `#${club.themeColor}` });

    const verifyUserName = (username) => {
        if (!username) {
            errorUpdate({ ...error, username: { 'border': '3px solid red' }, name: { 'border': '3px solid red' } });
        } else {
            API.verifUsername(`username=${username}`)
                .then((rep) => {
                    let tmp = {
                        name: newClub.name,
                        username: username,
                        androidPackageName: `com.cclub.${username}`,
                        iOSBundleId: `com.clubconnect.${username}`
                    };
                    errorUpdate({ ...initialError, email: error.email });
                    newClubUpdate({ ...newClub, ...tmp });
                    if (rep.statusCode === 200) {
                        errorUpdate({ ...error, username: { 'border': '' }, name: { 'border': '' } });
                        clubUpdate(newClub);
                    } else {
                        errorUpdate({ ...error, username: { 'border': '3px solid red' }, name: { 'border': '3px solid red' } });
                    }
                }).catch((err) => {
                    errorUpdate({ ...error });
                });
        }
    };

    const handleChange = (ev) => {
        if (ev.target.name && ev.target.name === 'name') {
            newClub[ev.target.name] = ev.target.value;
            verifyUserName(
              ev.target.value.toLowerCase()
              .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              .replace(/['"]/, '').replace(/ /g, '')
              .replace(/[_-]/g, '').trim()
            );
        }
        else if (ev.target.name && TextField.indexOf(ev.target.name) >= 0) {
            newClub[ev.target.name] = ev.target.value;
            if (ev.target.name === 'email') {
                if (!Lib.verifyEmail(ev.target.value)) {
                    errorUpdate({ ...error, email: {'border': '3px solid red'} });
                } else {
                    errorUpdate({ ...error, email: {'border': ''} });
                }
            } else if (ev.target.name === 'themeColor') {
                handleChangeColor(`#${newClub[ev.target.name]}`);
            }
        } else if (ev.target.name === 'iban') {
            newClub.bank = {};
            newClub.bank.iban = ev.target.value;
        } else if (ev.target.name === 'address') {
            newClub.contact = {};
            newClub.contact.address = ev.target.value;
        } else if (ev.target.name === 'facebook') {
            newClub.socials = {};
            newClub.socials.facebook = ev.target.value;
        }
        newClubUpdate({ ...newClub });
        clubUpdate(newClub);
    };

    const handleFileChange = (ev) => {
        const reader = new FileReader();
        let prefix = '';
        let name = '';
        if (ev.target.files.length > 0) {
            prefix = ev.target.name;
            name = ev.target.files[0].name;
            reader.onload = () => {
                newClub[prefix] = { name: name, prefix: prefix, file: reader.result };
                clubUpdate({ ...newClub });
            };
            reader.readAsDataURL(ev.target.files[0]);
        }
    };

    const handleChangeColor = (color) => {
        styleColorPickUpdater({ 'background': color });
    };

    return (<div className={'CreateClub'}>
        <form className={'CreateClubForm'}>
            <div>
                <h5>Informations</h5>
                <div className={ 'myRowFields' }>
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubName">Club Name</label>
                        <input onChange={ handleChange } aria-required={"true"} name={'name'} type="text" className="form-control" style={ error.name } id="InputClubName"
                               defaultValue={ newClub.name } placeholder="Club Name" required />
                    </div>
                    <div className={'separator'} />
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubEmail">Club Email</label>
                        <input onChange={ handleChange } aria-required={"true"} name={'email'} type="email" className="form-control" style={ error.email } id="InputClubEmail"
                               defaultValue={ newClub.email } placeholder={'Club Email'} required/>
                    </div>
                </div>
                <div className={ 'myRowFields' }>
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubManager">Manager Name</label>
                        <input onChange={ handleChange } name={'managerName'} type="text" className="form-control" id="InputClubManager"
                               defaultValue={ newClub.managerName } placeholder={'Club Manager Name'} required/>
                    </div>
                    <div className={'separator'} />
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubIBAN">IBAN</label>
                        <input onChange={ handleChange } name={'iban'} type="text" className="form-control" id="InputClubIBAN"
                               defaultValue={ newClub.bank.iban } placeholder={'Club IBAN'} />
                    </div>
                </div>
                <div className={ 'myRowFields' }>
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubADDR">Address</label>
                        <input onChange={ handleChange } name={'address'} type="text" className="form-control" id="InputClubADDR"
                               defaultValue={ newClub.contact.address } placeholder={'Club Postal Address'} />
                    </div>
                    <div className={'separator'} />
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubColor">Theme color</label>
                        <input style={ styleColorPick } onChange={ handleChange } name={'themeColor'} type="text" className="form-control" id="InputClubColor"
                               defaultValue={ newClub.themeColor } placeholder={'Club Color #...'} required/>
                    </div>
                </div>
                <div className={ 'myRowFields' }>
                    <div className={"form-group textField"}>
                        <label htmlFor="InputClubFB">Facebook</label>
                        <input onChange={ handleChange } name={'facebook'} style={ error.facebook } type="text" className="form-control" id="InputClubFB"
                               defaultValue={ newClub.socials.facebook } placeholder={'Club Facebook Profile'} required/>
                    </div>
                </div>

                <h5>Pictures / Icons</h5>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="InputClubAppIconDescript">App Icon</span>
                    </div>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="InputClubAppIcon" onChange={ handleFileChange }
                               aria-describedby="InputClubAppIconDescript" name={'appicon'} accept={"image/jpeg, image/png"} style={ error.appicon } />
                            <label className="custom-file-label"
                                   htmlFor="InputClubAppIcon">{ newClub.appicon.name || 'Image during club list view' }</label>
                    </div>
                </div>
                <div className={'separator'} />
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="InputClubCarouselDescript">Choose Club</span>
                    </div>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="InputClubCarousel" onChange={ handleFileChange }
                               aria-describedby="InputClubCarouselDescript" name={'carousel'} accept={"image/jpeg, image/png"} style={ error.carousel } />
                        <label className="custom-file-label"
                               htmlFor="InputClubCarousel">{ newClub.carousel.name || 'Image when choosing a club from the list' }</label>
                    </div>
                </div>
                <div className={'myRowFields'}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="InputClubBlurredDescript">Picture Club</span>
                        </div>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="InputClubBlurred" onChange={ handleFileChange }
                                   aria-describedby="InputClubBlurredDescript" name={'blurred'} accept={"image/jpeg, image/png"} />
                            <label className="custom-file-label"
                                   htmlFor="InputClubBlurred">{ newClub.blurred.name || 'Image above the menu in the app' }</label>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>);
};

export default CreateAdmin;
