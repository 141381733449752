import React, { useState }  from "react";
import './styles.scss';
import { SketchPicker } from "react-color";

const ClubGeneralView = ({club, clubUpdater, editor}) => {
  let [ visible, setVisible ] = useState('password');
  let [ pickerDisplay, pickerUpdate ] = useState(false);
  const setVisiblePassword = () => {
    setVisible( visible === 'password' ? 'text' : 'password');
  };
  const handleChange = (ev) => {
    let tmp = { ...club };
    if (ev.target.name === 'iban') {
      tmp.bank = {};
      tmp.bank.iban = ev.target.value;
    } else if (ev.target.name === 'android') {
      if (tmp.android === undefined) {
        tmp.android = {}
      }
      tmp.android.playStoreURL = ev.target.value;
    } else if (ev.target.name === 'ios') {
      if (tmp.ios === undefined) {
        tmp.ios = {}
      }
      tmp.ios.appStoreURL = ev.target.value;
    } else {
      tmp[ev.target.name] = ev.target.value;
    }
    clubUpdater({ ...tmp });
  };
  const handleContactChange = (ev) => {
    let tmp = { ...club };
    tmp.contact[ev.target.name] = ev.target.value;
    clubUpdater({ ...tmp });
  };
  const handleSocialChange = (ev) => {
    let tmp = { ...club };
    tmp.socials[ev.target.name] = ev.target.value;
    clubUpdater({ ...tmp });
  };
  const handleChangeColor = (color) => {
    clubUpdater({ ...club, themeColor: color.hex.slice(1) });
  };
  const handleClickTheme = (ev) => {
    if (!editor.state) {
      pickerUpdate(!pickerDisplay);
    }
  };
  const handleLiveServer = (ev) => {
    let tmp = { ...club };
    tmp.liveServer = tmp.liveServer || {};
    if (ev.target.name === 'rtmp') {

      tmp.liveServer.rtmp = ev.target.value;
    }
    if (ev.target.name === 'liveServerPlaylistUrl') {
      tmp.liveServer.liveServerPlaylistUrl = ev.target.value;
    }
    if (ev.target.name === 'rtmp_key') {
      tmp.liveServer.rtmp_key = ev.target.value;
    }
    clubUpdater({ ...tmp });
  };

  return (<>
    <div className={ 'mainContainer' }>
      <div className={ 'infoContainer' }>
        <h5>General Informations</h5>

        <div className="form-group">
          <label htmlFor="NameInput">Name</label>
          <input type="text" className="form-control" id="NameInput" defaultValue={ club.name }
                 name={'name'} onChange={ handleChange }
                 aria-describedby="Name of club" placeholder="Name of club" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="UsernameInput">Username</label>
          <input type="text" className="form-control" id="UsernameInput" defaultValue={ club.username }
                 name={'username'} onChange={ handleChange }
                 aria-describedby="Username of club" placeholder="username" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="ShortIDInput">ShortID (ObjectId in parse)</label>
          <input type="text" className="form-control" id="ShortIDInput" defaultValue={ club.shortID }
                 aria-describedby="ShortID of club" placeholder="shortID" disabled />
        </div>

        <div className="form-group">
          <label htmlFor="EmailInput">Email</label>
          <input type="email" className="form-control" id="EmailInput" defaultValue={ club.email }
                 name={'email'} onChange={ handleChange }
                 aria-describedby="Email of club" placeholder="email" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="managerNameInput">Manager Name</label>
          <input type="text" className="form-control" id="managerNameInput" defaultValue={ club.managerName }
                 name={'managerName'} onChange={ handleChange }
                 aria-describedby="Manager Name of club" placeholder="Manager Name" disabled={ editor.state }/>
        </div>

        <div className={"form-group"}>
          <div className={'pickerButton'} style={ { 'background': `#${club.themeColor}` } }
               onClick={ handleClickTheme }>Theme Color : { `#${club.themeColor}` }</div>
          { pickerDisplay ?
            <div className={ 'pickerPopover' }>
              <div className={ 'pickerCover' } onClick={ handleClickTheme } />
              <SketchPicker disableAlpha={ true } onChangeComplete={ handleChangeColor } color={`#${club.themeColor}`}/>
            </div> : null
          }
        </div>

        <div className="form-group">
          <label htmlFor="TypeOfClubInput">Type of Club</label>
          <div className="input-group mb-3">
            <input disabled={ editor.state } type="text" className="form-control" aria-label="Type of Club" defaultValue={ club.typeOfClub }
                   aria-describedby="Type of Club between Container CClub and white brands" />
            <div className="input-group-append">
              <div className="input-group-text">
                <input disabled={ editor.state } type="checkbox" defaultChecked={ club.container } aria-label="Is In Container Mode" />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="PasswordInput">Password</label>
          <div className="input-group mb-3">
            <input type={ visible } className="form-control" placeholder="Password" defaultValue={ club.password }
                   name={'password'} onChange={ handleChange }
                   aria-label="Password for BackOffice Club" aria-describedby="button-password" disabled={ editor.state }/>
              <div className="input-group-append adaptZIndex">
                <button className="btn btn-warning" type="button" id="button-password" onClick={ setVisiblePassword }>View</button>
              </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="IBANInput">IBAN</label>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="IBAN" defaultValue={ club.bank.iban }
                   name={'iban'} onChange={ handleChange }
                   aria-label="IBAN for Club" aria-describedby="button-password" disabled={ editor.state }/>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="CreatedAtInput">Create Date</label>
          <input defaultValue={ club.createdAt } disabled className="form-control" id="CreatedAtInput"
                 aria-describedby="Date of Create" placeholder="created at" />
        </div>

        <div className="form-group">
          <label htmlFor="UpdatedAtInput">Last Update Date</label>
          <input defaultValue={ club.updatedAt } disabled className="form-control" id="UpdatedAtInput"
                 aria-describedby="Date of last update" placeholder="last update" />
        </div>

        <h5>Boutique</h5>

        <div className="form-group">
          <label htmlFor="ShopIBANInput">IBAN de la Boutique</label>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="IBAN de la Boutique" defaultValue={ club.shopIban }
                   name={'shopIban'} onChange={ handleChange }
                   aria-label="Shop IBAN for Club" aria-describedby="button-password" disabled={ editor.state }/>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="ShopMarginInput">Marge des ventes de la boutique en %</label>
          <div className="input-group mb-3">
            <input type="number" className="form-control" placeholder="Marge des ventes de la boutique" defaultValue={ club.shopMargin }
                   name={'shopMargin'} onChange={ handleChange }
                   aria-label="IBAN for Club" aria-describedby="button-password" disabled={ editor.state }/>
          </div>
        </div>


      </div>
      <div className={ 'contactContainer' }>
        <h5>Contacts</h5>
        {/* Email */}
        <div className="form-group">
          <label htmlFor="EmailManagerInput">Email</label>
          <input type="email" className="form-control" id="EmailManagerInput" defaultValue={ club.contact.email }
                 name={'email'} onChange={ handleContactChange }
                 aria-describedby="Email of club" placeholder="email" disabled={ editor.state }/>
        </div>
        {/* Manager Phone */}
        <div className="form-group">
          <label htmlFor="PhoneManagerInput">Manager Phone</label>
          <input type="phone" className="form-control" id="PhoneManagerInput" defaultValue={ club.contact.phone }
                 name={'phone'} onChange={ handleContactChange }
                 aria-describedby="Phone of manager" placeholder="phone of manager" disabled={ editor.state }/>
        </div>
        {/* Club Phone */}
        <div className="form-group">
          <label htmlFor="FixManagerInput">Club Phone</label>
          <input type="phone" className="form-control" id="FixManagerInput" defaultValue={ club.contact.fixe }
                 name={'fixe'} onChange={ handleContactChange }
                 aria-describedby="Fixe of manager" placeholder="Fixe of manager" disabled={ editor.state }/>
        </div>
        {/* Address of Club */}
        <div className="form-group">
          <label htmlFor="AddressClubInput">Address of Club</label>
          <textarea id="AddressClubInput" className="form-control" value={ club.contact.address }
                    placeholder="Address of Club" name={'address'} onChange={ handleContactChange }
                    disabled={ editor.state } />
        </div>
        {/* Invoice Recipient */}
        <div className="form-group">
          <label htmlFor="invoiceRecipientInput">Invoice Recipient</label>
          <textarea id="invoiceRecipientInput" className="form-control" value={ club.contact.invoiceRecipient }
                    placeholder="Postal Address for invoice" name={'invoiceRecipient'} onChange={ handleContactChange }
                    disabled={ editor.state } />
        </div>
        {/* Booking Alert */}
        <div className="form-group">
          <label htmlFor="BookingAlertInput">Booking Alert Email</label>
          <input type="email" className="form-control" id="BookingAlertInput" defaultValue={ club.contact.bookingAlert }
                 name={'bookingAlert'} onChange={ handleContactChange }
                 aria-describedby="Booking Alert address" placeholder="Booking Alert Email" disabled={ editor.state }/>
        </div>
        {/* Invoice Email */}
        <div className="form-group">
          <label htmlFor="InvoiceEmailInput">Invoice Email</label>
          <input type="email" className="form-control" id="InvoiceEmailInput" defaultValue={ club.contact.invoiceEmail }
                 name={'invoiceEmail'} onChange={ handleContactChange }
                 aria-describedby="Invoice Email" placeholder="Invoice Email" disabled={ editor.state }/>
        </div>
        {/* On Download Email */}
        <div className="form-group">
          <label htmlFor="onDownloadEmailInput">On Download Email</label>
          <input type="email" className="form-control" id="onDownloadEmailInput" defaultValue={ club.contact.onDownloadEmail }
                 name={'onDownloadEmail'} onChange={ handleContactChange }
                 aria-describedby="on Download Email" placeholder="on Download Email" disabled={ editor.state }/>
        </div>
        {/* Club Connect Email */}
        <div className="form-group">
          <label htmlFor="clubConnectEmailInput">Club Connect Email</label>
          <input type="email" className="form-control" id="clubConnectEmailInput" defaultValue={ club.contact.clubConnectEmail }
                 name={'clubConnectEmail'} onChange={ handleContactChange }
                 aria-describedby="Invoice Recipient" placeholder="Club Connect Email" disabled={ editor.state }/>
        </div>
        {/* No-Reply Club Connect Email */}
        <div className="form-group">
          <label htmlFor="noReplyClubConnectEmailInput">No-Reply Club Connect Email</label>
          <input type="email" className="form-control" id="noReplyClubConnectEmailInput" defaultValue={ club.contact.noReplyClubConnectEmail }
                 name={'noReplyClubConnectEmail'} onChange={ handleContactChange }
                 aria-describedby="Invoice Recipient" placeholder="No-Reply Club Connect Email" disabled={ editor.state }/>
        </div>
        {/* Welcome Email From Address */}
        <div className="form-group">
          <label htmlFor="welcomeEmailFromAddressInput">Welcome Email From Address</label>
          <input type="email" className="form-control" id="welcomeEmailFromAddressInput" defaultValue={ club.contact.welcomeEmailFromAddress }
                 name={'welcomeEmailFromAddress'} onChange={ handleContactChange }
                 aria-describedby="Welcome Email From Address" placeholder="Welcome Email From Address" disabled={ editor.state }/>
        </div>
        {/* Email of Support */}
        <div className="form-group">
          <label htmlFor="supportEmailInput">Email of Support</label>
          <input type="email" className="form-control" id="supportEmailInput" defaultValue={ club.contact.supportEmail }
                 name={'supportEmail'} onChange={ handleContactChange }
                 aria-describedby="Email of Support" placeholder="Email of Support" disabled={ editor.state }/>
        </div>

      </div>
      <div className={ 'socialVideoContainer' }>
        <h5>Socials</h5>

        <div className="form-group">
          <label htmlFor="instagramInput">Instagram</label>
          <input type="text" className="form-control" id="instagramInput" defaultValue={ club.socials.instagram }
                 name={'instagram'} onChange={ handleSocialChange }
                 aria-describedby="Instagram" placeholder="Instagram" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="facebookInput">Facebook</label>
          <input type="text" className="form-control" id="facebookInput" defaultValue={ club.socials.facebook }
                 name={'facebook'} onChange={ handleSocialChange }
                 aria-describedby="facebook" placeholder="facebook" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="twitterInput">Twitter</label>
          <input type="text" className="form-control" id="twitterInput" defaultValue={ club.socials.twitter }
                 name={'twitter'} onChange={ handleSocialChange }
                 aria-describedby="twitter" placeholder="twitter" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="snapchatInput">Snapchat</label>
          <input type="text" className="form-control" id="snapchatInput" defaultValue={ club.socials.snapchat }
                 name={'snapchat'} onChange={ handleSocialChange }
                 aria-describedby="snapchat" placeholder="snapchat" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="websiteInput">Website</label>
          <input type="text" className="form-control" id="websiteInput" defaultValue={ club.socials.website }
                 name={'website'} onChange={ handleSocialChange }
                 aria-describedby="website" placeholder="website" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="playstoreUrl">PlayStore Url</label>
          <input type="text" className="form-control" id="playstoreUrl" defaultValue={ club.android.playStoreURL }
                 name={'android'} onChange={ handleChange }
                 aria-describedby="playstoreurl" placeholder="PlayStore Url" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="appstoreUrl">AppStore Url</label>
          <input type="text" className="form-control" id="appstoreUrl" defaultValue={ club.ios.appStoreURL }
                 name={'ios'} onChange={ handleChange }
                 aria-describedby="appstoreurl" placeholder="AppStore URL" disabled={ editor.state }/>
        </div>

        <h5>Vidéo</h5>

        <div className="form-group">
          <label htmlFor="vimeoApiKey">Vimeo API Key</label>
          <input type="text" className="form-control" id="vimeoApiKey" defaultValue={ club.vimeo_api_key }
                 name={'vimeo_api_key'} onChange={ handleChange }
                 aria-describedby="vimeoApiKey" placeholder="Vimeo API Key" disabled={ editor.state }/>
        </div>

        <h5>Live</h5>

        <div className="form-group">
          <label htmlFor="rtmp">Url du serveur</label>
          <input type="text" className="form-control" id="rtmp" defaultValue={ club?.liveServer?.rtmp }
                 name={'rtmp'} onChange={ handleLiveServer }
                 aria-describedby="rtmp" placeholder="Url du serveur" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="rtmp_key">Clé du live</label>
          <input type="text" className="form-control" id="rtmp_key" defaultValue={ club?.liveServer?.rtmp_key }
                 name={'rtmp_key'} onChange={ handleLiveServer }
                 aria-describedby="rtmp_key" placeholder="Clé du live" disabled={ editor.state }/>
        </div>

        <div className="form-group">
          <label htmlFor="liveServerPlaylistUrl">Url de la playlist</label>
          <input type="text" className="form-control" id="liveServerPlaylistUrl" defaultValue={ club?.liveServer?.liveServerPlaylistUrl }
                 name={'liveServerPlaylistUrl'} onChange={ handleLiveServer }
                 aria-describedby="liveServerPlaylistUrl" placeholder="Url de la playlist" disabled={ editor.state }/>
        </div>

      </div>
    </div>
  </>);
};

export default ClubGeneralView;
