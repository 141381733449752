import React  from "react";
import { Link } from 'react-router-dom';
import './styles.scss';

const AdminSelect = ({admin, selectAdmin }) => {
  return (<>
    <th scope="row">
      <input type={'checkbox'} name={admin._id} onChange={ selectAdmin }/>
    </th>
    <td><Link to={'/admin/detail?id='+admin._id}>{ new Date(admin.createdAt).toDateString() }</Link></td>
    <td><Link to={'/admin/detail?id='+admin._id}>{admin.corpo} / {admin.name}</Link></td>
    <td><Link to={'/admin/detail?id='+admin._id}>{ admin.metier }</Link></td>
    <td><a href={'mailto:'+ admin.email}>{admin.email}</a></td>
  </>);
};

export default AdminSelect;