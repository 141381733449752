import React from 'react';
import './App.scss';
import GeneralStats from './containers/GeneralStats';

const App = () => {
  return (
    <div className="App">
      <GeneralStats />
    </div>
  );
};

export default App;
