import React, {useRef} from "react";
import ControlledJsonEditor from "src/components/ClubMobileScreenCustomView/ControlledJsonEditor";

const _ = {
  text: {
    basicSection: 'Basic',
    lineName: 'Ligne',
    lineValue: [],
    textName: 'Texte',
    textValue: 'txt Je suis un text, modifie moi !',
    imageName: 'Image',
    imageValue: 'img https://s3-eu-west-1.amazonaws.com/com.clubconnect.bucket0/Clubs/clubconnectdev/uploads/banner-titre-1601626225.png 0 2.3',
    urlName: 'Bouton url',
    urlValue: 'https://clubconnect.fr/ Je suis le titre du lien',
    imgLinkName: 'Image url',
    imgLinkValue: {
      name: 'ButtonImageLink',
      props: {
        title: 'Je suis le titre',
        surTitle: 'Je suis le SUR-titre',
        imageUrl: 'https://s3-eu-west-1.amazonaws.com/com.clubconnect.bucket0/Clubs/clubconnectdev/uploads/banner-titre-1601626225.png',
        url: 'https://clubconnect.fr/',
        aspectRatio: 3.1,
      },
    },
    navButtonName: 'Bouton de navigation',
    navButtonValue: {
      name: 'ButtonImageNavigation',
      props: {
        title: 'Je suis le titre',
        subTitle: 'Je suis le sous-titre',
        imageUrl: 'https://s3-eu-west-1.amazonaws.com/com.clubconnect.bucket0/Clubs/OLaqBEHslw/pictureFileName_OLaqBEHslw_1653034982469.jpeg',
        iconName: 'rugby',
        rootNavigationScreen: 'trainingScreen',
        navigationScreen: 'ExerciseListScreen',
        aspectRatio: 3.1,
      },
    },
    advancedComponent: 'Composant avancé'
  },
};
const buttonProps = (v) => ({name: v, value: v});
_.componentsList = [{
  title: _.text.basicSection,
  buttonTheme: 'btn-primary',
  items: [
    {name: _.text.lineName, value: _.text.lineValue},
    {name: _.text.textName, value: _.text.textValue},
    {name: _.text.imageName, value: _.text.imageValue},
    {name: _.text.urlName, value: _.text.urlValue},
    {name: _.text.imgLinkName, value: _.text.imgLinkValue},
    {name: _.text.navButtonName, value: _.text.navButtonValue},
  ],
}, {
  title: 'Section button',
  buttonTheme: 'btn-info',
  items: [
    buttonProps("AccessCardButton"),
    buttonProps("BlogButton"),
    buttonProps("BookingButton"),
    buttonProps("CustomFormButton"),
    buttonProps("DocumentButton"),
    buttonProps("DueButton"),
    buttonProps("ExerciceButton"),
    buttonProps("FormReportButton"),
    buttonProps("InvitationButton"),
    buttonProps("InbodyButton"),
    buttonProps("InvoiceButton"),
    buttonProps("MandateButton"),
    buttonProps("MemberAreaButton"),
    buttonProps("NutritionButton"),
    buttonProps("PartnerButton"),
    buttonProps("PlanningButton"),
    buttonProps("ProductButton"),
    buttonProps("ScanQrCodeButton"),
    buttonProps("ShopButton"),
    buttonProps("ShopResamaniaButton"),
    buttonProps("TimerButton"),
    buttonProps("TrainingButton"),
    buttonProps("VodButton"),
    buttonProps("WorkoutReportListButton"),
    buttonProps("WorkoutPackButton"),
    buttonProps("WorkoutButton"),
  ],
}, {
  title: 'Theme button',
  buttonTheme: 'btn-warning',
  items: [
    buttonProps("ClubBannerButton"),
    buttonProps("CustomFormButtonLabel"),
    buttonProps("LiveButton"),
    buttonProps("ScanQrCodeButtonLabel"),
  ],
}, {
  title: 'Widget',
  buttonTheme: 'btn-success',
  items: [
    buttonProps("BlogWidget"),
    buttonProps("BlogStarWidget"),
    buttonProps("ExerciseWidget"),
    buttonProps("MembershipWidget"),
    buttonProps("PartnerWidget"),
    buttonProps("WorkoutWidget"),
    buttonProps("WorkoutWidgetHeap"),
    buttonProps("WorkoutPackWidget"),
    buttonProps("WorkoutPackWidgetHeap"),
    buttonProps("InvoiceWidget"),
  ],
}, {
  title: 'Expert',
  buttonTheme: 'btn-danger',
  items: [
    {name: _.text.advancedComponent, value: {name: 'Label', props: {children: 'Texte spécial', style: {textAlign: 'center', fontWeight: 'bold', fontStyle: 'italic'}, themeShadeAlt: 'c1'}}},
  ],
}];

const ScreenComponentsEditor = ({components, onComponentsChange, editor, assistMap}) => {
  const allowedModesRead = useRef(['view']).current;
  const allowedModesEdit = useRef(['tree', 'text']).current;

  const handleComponentsChange = (components) => {
    onComponentsChange(components)
  }

  const handleComponentPress = (componentName) => {
    onComponentsChange([...components, componentName]);
  }

  const handleAssistKeyPress = (name, value) => {
    onComponentsChange({...components, [name]: value});
  }

  return (
    <div className="mobileScreen-components">
      <div className="mobileScreen-components-editor">
        <div className="mobileScreen-editor">
          {editor.state ? (
              <ControlledJsonEditor
                value={components}
                mode="view"
                allowedModes={allowedModesRead}
              />
          ) : (
            <ControlledJsonEditor
              value={components} onChange={handleComponentsChange}
              mode="tree"
              allowedModes={allowedModesEdit}
            />
          )}
        </div>
      </div>
      {editor.state ? null : (
        <div className="mobileScreen-right-panel">
          <label className="mobileScreen-label">Ajouter un composant :</label>
          <div className="mobileScreen-components-list">
            <div className="mobileScreen-components-list-content">
              <ul>
                {assistMap ? Object.entries(assistMap).map(([name, value]) => (
                  <li key={name}>
                    <button className="btn btn-primary" onClick={() => handleAssistKeyPress(name, value)}>{name}</button>
                  </li>
                )) : _.componentsList.map(({title, items, buttonTheme}) => (
                  <React.Fragment key={title}>
                    <li>{title}</li>
                    <ul>
                      {items.map(({name, value}) => (
                        <li key={name}>
                          <button className={`btn ${buttonTheme}`} onClick={() => handleComponentPress(value)}>{name}</button>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenComponentsEditor;
