import Lib from "../Lib";

const path = 'illustration';

const illustration = {
  GET: (query) => Lib.makeRequest(path, "GET", null, query),
  GETBYID: (query, id) => Lib.makeRequest(`${path}/detail/${id}`, "GET", null, query),
  PATCH: (body, query, id) => Lib.makeRequest(`${path}/${id}`, "PATCH", body, query),
  POST: (body, query) => Lib.makeRequest(path, "POST", body, query),
  DELETE: (query) => Lib.makeRequest(path, "DELETE", null, query),
};
export default illustration;
