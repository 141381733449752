import React, { useState } from "react";
import './styles.scss';
import API from "../../api";

const AdminDetail = () => {
    const InputField = ["name", "email", "corpo", "metier", "avatar", "password"];
    let [ load, loadUpdate ] = useState(false);
    let [ modify, modifyUpdate ] = useState({ state: true, change: false, text: 'Editer' });
    let [ Admin, AdminUpdate ] = useState({});

    const loadData = (query) => {
        API.detailAdmin(null, window.location.search.slice(4)).then((admin) => {
            let tmp = admin.body;
            AdminUpdate(tmp);
        });
    };
    const updateData = () => {
        API.updateAdmin(null, Admin, window.location.search.slice(4)).then((admin) => {
            let tmp = admin.body;
            AdminUpdate(tmp);
        });
    };
    const handleChange = (ev) => {
        if (ev.target.name === 'modify') {
            modifyUpdate({ ...modify, state: !modify.state, text: (!modify.state ? "Editer" : "Sauvegarder") });
            if (modify.change) {
                updateData();
            }
        }
        else if (InputField.indexOf(ev.target.name) >= 0) {
            let ret = { ...Admin };
            ret[ev.target.name] = ev.target.value;
            AdminUpdate(ret);
            modifyUpdate({ ...modify, change: true });
        }
    };

    if (!load) {
        loadData();
        loadUpdate(true);
    }

    return (<div>
        <div className={"headerClub"}>
            <div>
                <button type="button" className="btn btn-primary" onClick={ handleChange } name={'modify'}>{ modify.text }</button>
            </div>
        </div>
        <div className={'AdminDetail'}>
            <div className={'jumbotron'}>
                <h3>Admin Details:</h3>
                <div className={"form-group"}>
                    <label htmlFor="InputAdminNAME">Name</label>
                    <input onChange={ handleChange } name={'name'} type="text" className="form-control" id="InputAdminNAME"
                           defaultValue={ Admin.name } placeholder={'Admin Name'} disabled={ modify.state }/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="InputAdminCORP">Corp.</label>
                    <input onChange={ handleChange } name={'corpo'} type="text" className="form-control" id="InputAdminCORP"
                           defaultValue={ Admin.corpo } placeholder={'Omega Corp'} disabled={ modify.state }/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="InputAdminJOBS">Jobs</label>
                    <input onChange={ handleChange } name={'metier'} type="text" className="form-control" id="InputAdminJOBS"
                           defaultValue={ Admin.metier } placeholder={'Jobs'} disabled={ modify.state }/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="InputAdminEMAIL">Email</label>
                    <input onChange={ handleChange } name={'email'} type="text" className="form-control" id="InputAdminEMAIL"
                           defaultValue={ Admin.email } placeholder={'Email'} disabled={ modify.state }/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="InputAdminPWD">Password</label>
                    <input onChange={ handleChange } name={'password'} type="password" className="form-control" id="InputAdminPWD"
                           defaultValue={ Admin.password } placeholder={'Admin Password'} disabled={ modify.state }/>
                </div>
            </div>
        </div>
    </div>);
};

export default AdminDetail;
