import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Modal from 'react-modal';

import * as serviceWorker from './serviceWorker';

import './index.scss';
import API from './api';
import App from './App';
import Login from './containers/Login';
import Illustration from './containers/Illustration';
import Admin from './containers/Admin';
import AdminDetail from './containers/AdminDetail';
import Club from "./containers/Club";
import ClubDetail from "./containers/ClubDetail";
import NavBar from "./components/NavBar";
import ClubStats from "./containers/ClubStats";
import Operation from "./containers/Operation";
import ClubActivity from "./containers/ClubActivity";
import ShopRefund from "./containers/ShopRefund";
import {ReactNotifications} from "src/components/Toast";

const BaseApp = () => {
    useEffect(() => {
        Modal.setAppElement('#root');
    }, []);

    return (<>
        <ReactNotifications/>
        <Router>
            <Route path={"/login"} exact component={Login} />
            { API.iAmAuth() ? (
                <>
                    <NavBar />
                    <Route path={"/"} exact component={ App }/>
                    <Route path={"/illustrations"} exact component={ Illustration } />
                    <Route path={"/admin"} exact component={ Admin } />
                    <Route path={"/admin/detail"} exact component={ AdminDetail } />
                    <Route path={"/club"} exact component={ Club } />
                    <Route path={"/club/detail"} exact component={ ClubDetail } />
                    <Route path={"/club/stats"} exact component={ ClubStats } />
                    <Route path={"/operation"} exact component={ Operation } />
                    <Route path={"/club/activity"} exact component={ ClubActivity } />
                    <Route path={"/shop/refund"} exact component={ ShopRefund } />
                </>
            ) : (
              <Redirect to={"/login"} />
            )}
        </Router>
    </>);
};

ReactDOM.render(<CookiesProvider><BaseApp/></CookiesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
