import React, {useEffect, useState} from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import API from "../../api";

import "./styles.scss";


/**
 * Display price from cents number
 * @param {string|number} price in cents value (1 => 0.01)
 * @param {string} currencyDisplay suffix string
 * @return {string} display value
 */
export function displayPrice(price, currencyDisplay = ' €') {
  const tabPrice = `${price}`.padStart(3, '0').split('');
  tabPrice.splice(tabPrice.length - 2, 0, ',');
  return tabPrice.join('') + currencyDisplay;
}

function ShopRefundTable({onValidationRefund, onCancellationRefund}){
  const [shopRefunds, shopRefundsSet] = useState(null);

  const displayAction = typeof onValidationRefund === 'function' && typeof onCancellationRefund === 'function';

  const fetchShopRefund = () => {
    API.searchShopRefund(`hasConfirmation=${!displayAction}`).then((results) => {
      const supplyData = results.body;
      shopRefundsSet(supplyData);
    });
  };

  useEffect(fetchShopRefund, [displayAction]);

  if (!Array.isArray(shopRefunds)) {
    return (
      <div className="loader-wrapper">
        <PropagateLoader
          size={50}
          color={"#17a2b8"}
        />
      </div>
    );
  }

  return (
    <div className="table-container">
      {!displayAction ? null : (
        <button
          type="button" className="btn btn-lg btn-success"
          onClick={ () => onValidationRefund(shopRefunds.map((shopRefund) => (shopRefund.id)))}
        >
          Valider tous les virements
        </button>
      )}

      <table className="table-styled">
        <thead>
        <tr>
          <th>N° de virement</th>
          <th>Club Id</th>
          <th>Club Name</th>
          <th>Période</th>
          <th>Montant du virement</th>
          <th>PSP reference</th>
          {!displayAction ? <th>Date de validation</th> : <th colSpan="2">Action</th>}
        </tr>
        </thead>
        <tbody>
        {shopRefunds.map((csr) => (
          <tr key={csr.id}>
            <td>{csr.id}</td>
            <td>{csr.club.id}</td>
            <td>{csr.club.name}</td>
            <td>{csr.periodDate}</td>
            <td>{displayPrice(csr.refundSum)}</td>
            <td>{csr.payoutReference}</td>
            {!displayAction ? <td>{csr.validationDate}</td> :
              <>
                <td>
                  <button type="button" className="btn btn-success" onClick={ () => onValidationRefund([csr.id]) } disabled={!csr.payoutReference}>
                    Valider sur Adyen
                  </button>
                </td>
                <td>
                  <button type="button" className="btn btn-danger" onClick={() => onCancellationRefund([csr.id])}>
                    Annuler le virement
                  </button>
                </td>
              </>
            }
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default ShopRefundTable;
