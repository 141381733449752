import React from "react";
import './styles.scss';

const CreateAdmin = (admin) => {
    let InputField = ['name', 'corpo', 'email', 'metier', 'password', 'avatar'];
    const handleChange = (ev) => {
        if (InputField.indexOf(ev.target.name) >= 0) {
            let tmp = { ...admin.admin };
            tmp[ev.target.name] = ev.target.value;
            admin.adminUpdate(tmp);
        }
    };

    return (<form>
        <div className="form-group">
            <label htmlFor="InputAdminName">Admin Name</label>
            <input onChange={ handleChange } name={'name'} type="text" className="form-control" id="InputClubName"
                   defaultValue={ admin.name } placeholder="Enter Admin Name" />
        </div>
        <div className="form-group">
            <label htmlFor="InputAdminCorp">Admin Corp.</label>
            <input onChange={ handleChange } name={'corpo'} type="text" className="form-control" id="InputAdminCorp"
                   defaultValue={ admin.corpo } placeholder="Enter Admin Corp." />
        </div>
        <div className="form-group">
            <label htmlFor="InputAdminEmail">Admin Email</label>
            <input onChange={ handleChange } name={'email'} type="email" className="form-control" id="InputClubEmail"
                   defaultValue={ admin.email } placeholder="Enter Admin Email" />
        </div>
        <div className="form-group">
            <label htmlFor="InputAdminJob">Admin Job</label>
            <input onChange={ handleChange } name={'metier'} type="text" className="form-control" id="InputClubJob"
                   defaultValue={ admin.metier } placeholder="Enter Admin Job" />
        </div>
        <div className="form-group">
            <label htmlFor="InputAdminPassword">Admin Password</label>
            <input onChange={ handleChange } name={'password'} type="password" className="form-control" id="InputClubPassword"
                   defaultValue={ admin.password } placeholder="Enter Admin Password" />
        </div>
    </form>);
};

export default CreateAdmin;