import React, {useCallback, useEffect, useRef, useState} from "react";
import HashLoader from 'react-spinners/HashLoader';
import API from "src/api";
import IllustrationActionBarList from "./IllustrationActionBarList";
import './styles.scss';
import IllustrationTable from "src/containers/Illustration/IllustrationTable";

const Illustrations = () => {
  const [clubs, setClubs] = useState(null);
  const illustrationLabelMap = useRef();
  const [labels, setLabels] = useState(null);
  const illustrationFull = useRef();
  const [illustrations, setIllustrations] = useState(null);

  const onLoading = () => {
    setIllustrations(null);
  }

  const fetchData = () => {
    setIllustrations(null);
    illustrationLabelMap.current = {};
    Promise.all([
      API.loadClub('limit=1000&fields={"disabled": false, "active": true}&keys=name'),
      API.loadIllustration(),
    ])
      .then(([supplyClub, supplyIllustration]) => {
        setClubs(supplyClub.body.clubs
          .map((c) => ({value: c._id, label: c.name})).sort((a, b) => (a.label.localeCompare(b.label)))
        );
        supplyIllustration.body.forEach((i) => illustrationLabelMap.current[i.id] = i.label);
        setLabels([...new Set(Object.values(illustrationLabelMap.current))].filter((l) => typeof l === 'string')
          .map((l) => ({value: l, label: l})).sort((a, b) => (a.label.localeCompare(b.label)))
        );
        illustrationFull.current = supplyIllustration.body;
        setIllustrations(illustrationFull.current);
      });
  }

  useEffect(fetchData, []);

  const handleLabelSelected = useCallback((selectedLabels) => {
    if (selectedLabels.length <= 0) {
      return setIllustrations(illustrationFull.current);
    }
    setIllustrations(illustrationFull.current.filter((i) => selectedLabels.includes(i.label)));
  }, [setIllustrations]);

  const handleLabelUpdate = (illustration) => {
    illustrationLabelMap.current[illustration.id] = illustration.label;
    setLabels([...new Set(Object.values(illustrationLabelMap.current))].filter((l) => typeof l === 'string')
      .map((l) => ({value: l, label: l})).sort((a, b) => (a.label.localeCompare(b.label)))
    );
  };

  return (
    <section>
      <IllustrationActionBarList
        onCreation={onLoading} onCreated={fetchData}
        labels={labels} onLabelSelected={handleLabelSelected}
      />
      <div>
        {illustrations === null ? <div className="IllustrationLoader"><HashLoader size={30} color={"#17a2b8"}/></div>
          : (
            <IllustrationTable
              illustrations={illustrations} clubs={clubs} labels={labels} onLabelUpdate={handleLabelUpdate}
            />
          )
        }
      </div>
    </section>
  );
};

export default Illustrations;
