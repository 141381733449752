import React, { useState } from "react";
import './styles.scss';
import API from "../../api";

const ClubPicturesView = ({club, clubUpdater, editor}) => {
  let [ appicon, appiconUpdater ] = useState({ name: '', prefix: 'appicon', file: {} });
  let [ blurred, blurredUpdater ] = useState({ name: '', prefix: 'blurred', file: {} });
  let [ banner, bannerUpdater ] = useState({ name: '', prefix: 'banner', file: {} });
  let [ carousel, carouselUpdater ] = useState({ name: '', prefix: 'carousel', file: {} });

  const upload = (files, prefix, updater) => {
    const reader = new FileReader();
    let name = files[0].name;
    reader.onload = () => {
      updater({ name: name, prefix: prefix, file: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  const handleChange = (ev) => {
    switch (ev.target.name) {
      case 'appicon':
        upload(ev.target.files, 'appicon', appiconUpdater);
        break;
      case 'blurred':
        upload(ev.target.files, 'blurred', blurredUpdater);
        break;
      case 'banner':
        upload(ev.target.files, 'banner', bannerUpdater);
        break;
      case 'carousel':
        upload(ev.target.files, 'carousel', carouselUpdater);
        break;
      default:
        break;
    }

  };
  const applyPicturesChange = () => {
    let p = [];
    if (appicon.name.length > 0) {
      p.push(API.createDocument({ name: `${appicon.name}`, prefix: `${appicon.prefix}`, base64: `${appicon.file}`}))
    }
    if (blurred.name.length > 0) {
      p.push(API.createDocument({ name: `${blurred.name}`, prefix: `${blurred.prefix}`, base64: `${blurred.file}`}))
    }
    if (banner.name.length > 0) {
      p.push(API.createDocument({ name: `${banner.name}`, prefix: `${banner.prefix}`, base64: `${banner.file}`}))
    }
    if (carousel.name.length > 0) {
      p.push(API.createDocument({ name: `${carousel.name}`, prefix: `${carousel.prefix}`, base64: `${carousel.file}`}))
    }
    Promise.all(p)
      .then((response) => {
        response.forEach((pict) => {
          let tmp = pict.body;
          switch (tmp.prefix) {
            case 'appicon':
              clubUpdater((c) => ({ ...c, pictures: { ...c.pictures, appicon: tmp } }));
              break;
            case 'blurred':
              clubUpdater((c) => ({ ...c, pictures: { ...c.pictures, blurred: tmp } }));
              break;
            case 'banner':
              clubUpdater((c) => ({ ...c, pictures: { ...c.pictures, banner: tmp } }));
              break;
            case 'carousel':
              clubUpdater((c) => ({ ...c, pictures: { ...c.pictures, carousel: [ tmp ] } }));
              break;
          }

        });
      }).catch((error) => {
        console.log(error, 'error promise upload');
      });
  };

  return (<>
    <div className={ 'mainTextContainer' }>
      <div className={ 'textContainer' }>

        <div className="form-group">
          <label htmlFor="appiconInput">
            {club.pictures.appicon ? <a target="_blank" rel="noopener noreferrer"
              href={club.pictures.appicon.path}>App Icon</a> : 'App Icon'
            }
          </label>
          <input type="file" className="form-control" id="appiconInput" disabled={ editor.state }
                 name={ 'appicon' } onChange={ handleChange } />
        </div>

        <div className="form-group">
          <label htmlFor="blurredInput">
            {club.pictures.blurred ?
                <a target="_blank" rel="noopener noreferrer"
                   href={club.pictures.blurred.path}>Picture Club</a> : 'Picture Club'
            }
          </label>
          <input type="file" className="form-control" id="blurredInput" disabled={ editor.state }
                 name={ 'blurred' } onChange={ handleChange } />
        </div>

        <div className="form-group">
          <label htmlFor="carouselInput">
          {club.pictures.carousel[0] ?
              <a target="_blank" rel="noopener noreferrer"
                 href={club.pictures.carousel[0].path}>Choose Club</a> : 'Choose Club'
          }
          </label>
          <input type="file" className="form-control" id="carouselInput" disabled={ editor.state }
                 name={'carousel'} onChange={handleChange} />
        </div>
      </div>

      <div className={ 'textContainer' }>

        <div className="form-group">
          <label htmlFor="appiconShow">App Icon:</label><br/>
          {club.pictures.appicon &&
            <img id={'appiconShow'} alt={club.pictures.appicon.name} src={club.pictures.appicon.path} height={'100'}/>
          }
        </div>

        <div className="form-group">
          <label htmlFor="blurredShow">Picture Club:</label><br/>
          {club.pictures.blurred &&
            <img id={'blurredShow'} alt={club.pictures.blurred.name} src={club.pictures.blurred.path} height={'100'}/>
          }
        </div>

        <div className="form-group">
          <label htmlFor="carouselShow">Choose Club:</label><br/>
          { club.pictures.carousel[0] &&
            <img id={'carouselShow'} alt={ club.pictures.carousel[0].name } src={ club.pictures.carousel[0].path } height={'100'}/>
          }
        </div>

      </div>
    </div>
    <div className={'buttonChangePictures'}>
      <button className={'btn btn-warning'} disabled={ editor.state } onClick={applyPicturesChange}>Apply Change on photo</button>
    </div>
  </>);
};

export default ClubPicturesView;
