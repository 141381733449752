import React from "react";
import { NavLink } from "react-router-dom";
import './styles.scss';
import Disconnect from '../Disconnect';

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <NavLink
                className="navbar-brand"
                to="/">
                Admin ClubConnect
            </NavLink>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse"
                id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/">
                            Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/club">
                            Clubs</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/admin">
                            Admin</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/illustrations">
                            Illustrations
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/operation">Opération</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/club/activity">Activité des clubs</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/shop/refund">Boutique</NavLink>
                    </li>
                </ul>
                {/*<form className="form-inline my-2 my-lg-0">*/}
                {/*    <input*/}
                {/*        className="form-control mr-sm-2"*/}
                {/*        type="search"*/}
                {/*        placeholder="Search"*/}
                {/*        aria-label="Search" />*/}
                {/*    <button*/}
                {/*        className="btn btn-outline-success my-2 my-sm-0"*/}
                {/*        type="submit">Search</button>*/}
                {/*</form>*/}
                &nbsp;
                <ul className="navbar-nav mr-left">
                    <li className="nav-item active">
                        <Disconnect />
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
