import React, { useState } from "react";
import './styles.scss';
import CreateDocuments from "../CreateDocuments";
import CreateClub from "../CreateClub";
import CreateAdmin from "../CreateAdmin";
import API from "../../api";

const Modal = ({selector, FieldModal, selectorUpdate, setUploadFile, retUpdate, type, uploadFile, search}) => {
    const initialClub = {
      name: '',
      email: '',
      username: '',
      themeColor: '0de034',
      managerName: '',
      password: '',
      contact: { address: '' },
      socials: { facebook: '' },
      androidPackageName: 'com.cclub.',
      iOSBundleId: 'com.clubconnect.',
      appicon: {},
      blurred: {},
      carousel: {},
      // banner: {},
      bank: { iban: '' }
    };
    let initialError = {
      name: {'border': ''},
      username: {'border': ''},
      email: {'border': ''},
      appicon: {'border': ''},
      carousel: {'border': ''},
      facebook: {'border': ''}
    };
    let [ admin, adminUpdate ] = useState({
        name: '', corpo: '', email: '', metier: '',
        password: ''
    });
    let [ error, errorUpdate ] = useState(initialError);
    let [ club, clubUpdate ] = useState(initialClub);
    const initialRetour = {show: false, wait:false, icon: '', title: '', text: '', callback: () => {} };

    const CapitalizeFirst = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const title = (type) => {
        return `New ${CapitalizeFirst(type)}`;
    };

    const newElements = (ev) => {
        let p = [];
        let pClub = [];
        retUpdate({ ...initialRetour, wait: true });
        switch (type) {
            case 'documents':
                p.push(API.createDocument({ name: `_${ uploadFile.name }`, prefix: `${ uploadFile.prefix }`, base64: uploadFile.file }));
                break;
            case 'admin':
                p.push(API.createAdmin({ ...admin }));
                break;
            case 'club':
              // check error before create anything
              if (club.email.length < 1 || error.email.border !== '') {
                p.push(new Error('Email invalid or empty'));
                return errorUpdate({ ...error, email: { 'border': '3px solid red' } });
              }
              if (club.username.length < 1 || error.username.border !== '') {
                p.push(new Error('Username invalid or empty'));
                return errorUpdate({ ...error, username: { 'border': '3px solid red' }, name: { 'border': '3px solid red' } });
              }
              if (club.socials.facebook.length < 1 || error.facebook.border !== '') {
                p.push(new Error('Facebook needed'));
                return errorUpdate({ ...error, facebook: { 'border': '3px solid red' } });
              }
              // upload image and put _id into club before save club
              if (club.appicon && club.appicon.name) {
                  pClub.push(API.createDocument({ clubName: club.username, name: `_${club.appicon.name}`, prefix: `${club.appicon.prefix}`, base64: club.appicon.file }));
              }
              if (club.blurred && club.blurred.name) {
                  pClub.push(API.createDocument({ clubName: club.username, name: `_${club.blurred.name}`, prefix: `${club.blurred.prefix}`, base64: club.blurred.file }));
              }
              if (club.carousel && club.carousel.name) {
                  pClub.push(API.createDocument({ clubName: club.username, name: `_${club.carousel.name}`, prefix: `${club.carousel.prefix}`, base64: club.carousel.file }));
              }
              // if (club.banner && club.banner.name) {
              //   pClub.push(API.createDocument({ clubName: club.username, name: `_${club.banner.name}`, prefix: `${club.banner.prefix}`, base64: club.banner.file }));
              // }
              Promise.all(pClub)
                  .then((data) => {
                      data.forEach((ret) => {
                         let inJson = ret.body;
                         if (inJson.prefix && inJson.path) {
                             switch (inJson.prefix) {
                                 case 'appicon':
                                     club = { ...club, appicon: inJson.path };
                                     break;
                                 case 'blurred':
                                     club = { ...club, blurred: inJson.path };
                                     break;
                                 case 'carousel':
                                     club = { ...club, carousel: inJson.path };
                                     break;
                                 // case 'banner':
                                 //     club = { ...club, banner: inJson.path };
                                 //     break;
                                 default:
                                     break;
                             }
                         }
                      });
                      p.push(
                          API.createClub({ ...club }).then((data) => {
                            if (data.statusCode < 200 && data.statusCode >= 300) {
                              clubUpdate(initialClub);
                              alert('Operation Errored');
                              // return setTimeout(() => search(''), 500);
                            } else {
                              clubUpdate(initialClub);
                              alert('Operation Complete');
                              // return setTimeout(() => search(''), 500);
                            }
                          }).catch((err) => {
                            clubUpdate(initialClub);
                            console.log(err, "Error create club");
                          })
                      );
                  });
              break;
            default:
              return;
        }
        Promise.all(p)
            .then((ret) => {
              console.log(ret, "retour of new elements");
              if (ret.message) {
                return alert(ret.message);
              } else {
                return setTimeout(() => search(''), 500);
              }
            });
    };
    return (<div className="modal fade" id="modalCreate" tabIndex="-1" role="dialog"
                 aria-labelledby="ModalCreationUniversal" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="MyModalSizer modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="ModalCreationUniversal">{ title(type) }</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                { type === 'documents' ?
                    <CreateDocuments selectField={ FieldModal } selector={ selector }
                                     selectorUpdate={ selectorUpdate } setUploadFile={ setUploadFile } /> :
                    ''}
                { type === 'club' ?
                    <CreateClub club={ club } clubUpdate={ clubUpdate }
                                initialError={ initialError } error={ error } errorUpdate={ errorUpdate }/> :
                    ''}
                { type === 'admin' ?
                    <CreateAdmin admin={ admin } adminUpdate={ adminUpdate }/> :
                    ''}
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={ newElements }>Save changes</button>
                </div>
            </div>
        </div>
    </div>);
};

export default Modal;
