import Cookies from 'universal-cookie';
import API from '../api';
import Lib from '../Lib';

const isOauth = () => {
    const cookie = new Cookies();
    if (cookie.get('token') !== undefined) {
        return true;
    } else if (cookie.get('token') === undefined && cookie.get('refreshToken') !== undefined) {
        API.signRefresh({ refresh: cookie.get('refreshToken')})
            .then((reponse) => {
               const rep = reponse.body;
               cookie.set('token', rep.token, { path: '/', maxAge: 604800 });
               if (cookie.get('remember')) {
                   cookie.set('refreshToken', rep.refreshToken, { path: '/', maxAge: 604800 * 2 });
               }
               window.location.assign(window.location.href = Lib.windowOrigin() );
            }).catch((err) => {
                disconnect();
                alert('An error occur when auto re-connect you with refreshToken, I disconnect you');
            });
    }
    return false;
};

const disconnect = () => {
    const cookie = new Cookies();
    cookie.remove('token');
    cookie.remove('remember');
    cookie.remove('refreshToken');
};

let auth = {
    GET: () => isOauth(),
    POST: (path, body, query) => Lib.makeRequest(path, "POST", body, query),
    POST_REFRESH: (path, body, query) => Lib.makeRequest(path, "POST", body, query),
    DELETE: () => disconnect()
};

export default auth;
