import {useEffect, useState} from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import API from 'src/api';
import './styles.scss';

const _ = {
  fields: [
    'firstName', 'lastName', 'email', 'phone', 'company',
    'addressLine1', 'addressCity', 'addressZip', 'addressCountry',
  ],
  fieldNames: {
    firstName: 'Prénom du gérant',
    lastName: 'Nom du gérant',
    email: 'Email du gérant',
    phone: 'Manager Phone',
    company: 'Nom du club',
    addressLine1: 'Adresse Rue',
    addressCity: 'Adresse Ville',
    addressZip: 'Adresse Code Postal',
    addressCountry: 'Adresse Pays',
  }
}

function ClubChargebeeView({club, reloadData}){
  const [isQuerying, setQuerying] = useState(false);
  const [clubData, setClubData] = useState({});

  useEffect(() => {
    const nameManager = (club.managerName ?? '').split(' ');
    const address = (club.contact?.address ?? '').split('\n');
    setClubData({
      firstName: nameManager[0],
      lastName: nameManager[1],
      email: club.contact?.email,
      phone: club.contact?.phone,
      company: club.name,
      addressLine1 : address[0],
      addressCity : address[1],
      addressZip : address[2],
      addressCountry : address[3] ?? 'FR'
    });
  }, [club]);

  if(club.chargebeeId){
    return (
      <div className="ChargebeeMain">
        Customer Chargebee créer.
        <div>{club.chargebeeLink}</div>
        <a target="_blank" rel="noreferrer" href={club.chargebeeLink}>Lien vers Chargebee : {club.chargebeeId}</a>
      </div>
    );
  }

  if(isQuerying) {
    return (
      <div className="ChargebeeMain">
        <PropagateLoader/>
      </div>
    );
  }

  const handleCreate = () => {
    const confirmed = window.confirm('Attention, le client chargebee sera créer selon les informations actuellement présentent.');
    if(!confirmed) return;
    setQuerying(true);
    return API.createClubChargebeeCustomer(club._id, {
      name: club.name,
      user: club.user,
    })
      .finally(reloadData)
  };

  return (
    <div className="ChargebeeMain">
      <div className="ChargebeeInfo">
        <h5>Informations pour créer le client dans Chargebee</h5>
        {_.fields.map((field) => (
          <div className="form-group">
            <label>{_.fieldNames[field]}</label>
            <input type="text" className="form-control" value={clubData[field]} disabled={true}/>
          </div>
        ))}
      </div>
      <button type="button" className={`btn btn-warning`} onClick={handleCreate}>
        Créer le client Chargebee avec ces informations
      </button>
    </div>

  )
}

export default ClubChargebeeView;
