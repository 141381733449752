import React, {useState} from 'react';
import CircleLoader from 'react-spinners/CircleLoader';
import API from "../../api";
import ShopCommandTable from "./ShopCommandTable";
import ShopRefundTable from "./ShopRefundTable";

import "./styles.scss";


/**
 * Display price from cents number
 * @param {string|number} price in cents value (1 => 0.01)
 * @param {string} currencyDisplay suffix string
 * @return {string} display value
 */
export function displayPrice(price, currencyDisplay = ' €') {
  const tabPrice = `${price}`.padStart(3, '0').split('');
  tabPrice.splice(tabPrice.length - 2, 0, ',');
  return tabPrice.join('') + currencyDisplay;
}

function ShopRefund(){
  const [isQuerying, setQuerying] = useState(false);

  const handleCreationRefund = (clubIds) => {
    setQuerying(true);
    Promise.all(clubIds.map((clubId) => API.createShopRefund({clubId})))
      .finally(() => setQuerying(false));
  };

  const handleValidationRefund = (shopRefundIds) => {
    setQuerying(true);
    Promise.all(shopRefundIds.map((shopRefundId) => API.patchShopRefund(shopRefundId, {validationDate: new Date()})))
      .finally(() => setQuerying(false));
  };

  const handleCancellationRefund = (shopRefundIds) => {
    setQuerying(true);
    Promise.all(shopRefundIds.map((shopRefundId) => API.deleteShopRefund(shopRefundId)))
      .finally(() => setQuerying(false));
  };

  if (isQuerying) {
    return (
      <div className="clubActivityMain">
        <p>Traitement...</p>
        <CircleLoader
          size={300}
          color={"#17a2b8"}
        />
      </div>
    );
  }

  return (
    <div className="clubActivityMain">
      <div className="clubActivityGrid">
        <h2>Somme à rembouser aux clubs</h2>
        <ShopCommandTable onCreationRefund={handleCreationRefund}/>
        <h2>Virement à valider</h2>
        <ShopRefundTable onValidationRefund={handleValidationRefund} onCancellationRefund={handleCancellationRefund}/>
        <h2>Virement validé</h2>
        <ShopRefundTable/>
      </div>
    </div>
  );
}

export default ShopRefund;
