import Lib from "../Lib";

let club = {
  GET: (path, query) => Lib.makeRequest(path, "GET", null, query),
  GETBYID: (path, query, id) => Lib.makeRequest(`${path}/detail/${id}`, "GET", null, query),
  GETUSERNAME: (path, query) => Lib.makeRequest(`${path}/verifyUsername`, "GET", null, query),
  GETACTIVITY: (path, query) => Lib.makeRequest(`${path}/activity`, "GET", null, query),
  PATCH: (path, body, query, id) => Lib.makeRequest(`${path}/${id}`, "PATCH", body, query),
  POST: (path, body, query) => Lib.makeRequest(`${path}/`, "POST", body, query),
  POSTChargebeeCustomer: (path, body, id) => Lib.makeRequest(`${path}/${id}/chargebeeCustomer`, "POST", body),
  DELETE: (path, query) => Lib.makeRequest(`${path}/`, "DELETE", null, query),
};
export default club;
