import React, { useState } from "react";
import './styles.scss';
import ClubSelect from '../../components/ClubSelect'
import API from "../../api";
import ActionBarList from "../../components/ActionBarList";
import Modal from "../../components/Modal";

const Club = () => {
    const type = 'club';
    const initialRetour = {show: false, wait:false, icon: '', title: '', text: '', callback: () => {} };
    const Field = [
        { name: 'select', value: 'Select' },
        { name: "createdAt", value: "Creer le" },
        { name: "updatedAt", value: "Dernière MaJ" },
        { name: "typeofclub", value: "Type Of Club" },
        { name: "username", value: "UserName" },
        { name: "name", value: "Nom de salle" },
        { name: "managerName", value: 'Manager Name'},
        { name: "email", value: 'Email'}
    ];
    const FieldModal = [
        {name: "avatar", value: "Avatar"},
    ];
    let [ Clubs, ClubsUpdate ] = useState([]);
    let [ load, loadUpdate ] = useState(false);
    let [ count, countUpdate ] = useState(0);
    let [ criteria, criteriaUpdate ] = useState("");
    let [ live, liveUpdate ] = useState(false);
    let [ active, activeUpdate ] = useState(false);
    // eslint-disable-next-line
    let [ uploadFile, setUploadFile ] = useState({ name: '', prefix: '', file: {} });
    let [ selector, selectorUpdate ] = useState(FieldModal[0].name);
    let [ selectedClub, selectClub ] = useState([]);
    let [ retour, retUpdate ] = useState(initialRetour);

    const search = (query) => {
        if (criteria.length > 0) query = `limit=2000&query=${criteria}`;
        else query = 'limit=2000';

        API.loadClub(query).then((clubs) => {
            let tmp = clubs.body;

            if (!!active) tmp.clubs = tmp.clubs.filter(c => !!c.active);
            if (!!live) tmp.clubs = tmp.clubs.filter(c => !!c.liveServer);

            ClubsUpdate(tmp.clubs);
            countUpdate(tmp.clubs.length);
        }).catch((er) => {
            ClubsUpdate([]);
            countUpdate(0);
            alert(er.message);
        });
    };
    const handleChange = (ev) => {
        const { name, value, checked } = ev.target;
        if (name === 'search') criteriaUpdate(value.replace('', ''));
        else if (name === 'activeClub') activeUpdate(checked);
        else if (name === 'liveClub') liveUpdate(checked);
    };
    if (Clubs.length <= 0 && !load) {
        search(criteria);
        loadUpdate(true);
    }
    const selectClubs = (ev) => {
        if (selectedClub.indexOf(ev.target.name) >= 0) {
            selectedClub.splice(selectedClub.indexOf(ev.target.name), 1);
        } else {
            selectedClub.push(ev.target.name);
        }
        selectClub(selectedClub);
    };

    return (<div>
        <div className={"headerClub"}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: "0 1 110px" }}>
                <label>
                    Club actif
                    <input type={'checkbox'} onChange={handleChange} name={"activeClub"} checked={active} style={{ margin: '0 .5rem'}}/>
                </label>
                <label>
                    Club Live
                    <input type={'checkbox'} onChange={handleChange} name={"liveClub"} checked={live} style={{ margin: '0 .5rem'}}/>
                </label>
            </div>
            <ActionBarList
                count={ count } retour={ retour} type={ type } retUpdate={ retUpdate } initialRetour={ initialRetour }
                handleChange={ handleChange } search={ search } selectedDocument={ selectedClub } />
        </div>
        <div>
            <table className="table table-striped">
                <thead>
                <tr className={ 'firstLane' }>
                    {Field.map((el, key) => {
                        return (
                            <th scope="col" key={key} >{el.value}</th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {Clubs.map((el, key) => {
                    return (
                        <tr className={ 'firstLane' } key={key}>
                            <ClubSelect club={el} selectClub={ selectClubs }/>
                        </tr>);
                })}
                </tbody>
            </table>
        </div>
        <Modal FieldModal={ FieldModal } selector={ selector } selectorUpdate={ selectorUpdate }
               retUpdate={ retUpdate } search={ search } setUploadFile={ setUploadFile } type={'club'}/>
    </div>);
};

export default Club;
