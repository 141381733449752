import React from "react";
import { Chart } from "react-google-charts";

import './styles.scss';
import API from "../../api";

export default class ClubStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepNotification: "LOADING",
            stepDownload: "LOADING",
            stepInvitation: "LOADING",
            beginDate: '',
            endDate: '',
            today: `${new Date().getFullYear()}-${(new Date().getMonth()).toString().length > 1 ? new Date().getMonth() : new Date().getMonth() + 1}-${new Date().getDate()}`,
            query: `club=${ window.location.search.slice(4) }`
        };
        this.options = {
            seriesType: "ColumnChart",
            optionsDown: {
                title: 'Number of Downloads',
                chartArea: { width: '85%', height: '60%' },
                isStacked: true,
                vAxis: { title: 'Total Download', minValue: 0 },
                hAxis: { title: 'Date' },
            },
            optionsInvite: {
                title: 'Number of Invitations',
                chartArea: { width: '85%', height: '60%' },
                hAxis: { title: 'Date' },
                vAxis: { title: 'Invitation', minValue: 0 },
            },
            optionsNotif: {
                title: 'Number of Notifications',
                chartArea: { width: '85%', height: '60%' },
                vAxis: { title: 'Total Notifications', minValue: 0 },
                hAxis: { title: 'Date' },
            },
        };
        this.handleDateChange = (ev) => {
            let tmp = { ...this.state };
            tmp[ev.target.name] = ev.target.value;
            tmp.query = `club=${ window.location.search.slice(4) }`;

            if (tmp.beginDate.length > 1 && !tmp.query.match(/beginDate/)) {
                tmp.query += `&beginDate=${ tmp.beginDate }`;
            }
            if (tmp.endDate.length > 1 && !tmp.query.match(/endDate/)) {
                tmp.query += `&endDate=${ tmp.endDate }`;
            }
            this.setState(tmp);
        };
    };

    componentDidMount() {
        let query = this.state.query;
        this.loadStats(query);
    };

    loadStats(query) {
        let p = [];

        this.setState({
            stepNotification: "LOADING",
            stepDownload: "LOADING",
            stepInvitation: "LOADING"
        });
        p.push(API.getDownload(query));
        p.push(API.getInvitation(query));
        p.push(API.getNotification(query));

        Promise.all(p)
            .then((data) => {
                data.forEach((stats, ind) => {
                   let tmp = {};
                   if (ind === 0) {
                       tmp = stats.body;
                       if (tmp.stats.length <= 1) {
                           this.setState({ stepDownload: "EMPTY" });
                       } else {
                           this.setState({ stepDownload: "OK", dataDownload: tmp.stats });
                       }
                   } else if (ind === 1) {
                       tmp = stats.body;
                       if (tmp.stats.length <= 1) {
                           this.setState({ stepInvitation: "EMPTY" });
                       } else {
                           this.setState({ stepInvitation: "OK", dataInvitation: tmp.stats });
                       }
                   } else if (ind === 2) {
                       tmp = stats.body;
                       if (tmp.stats.length <= 1) {
                           this.setState({ stepNotification: "EMPTY" });
                       } else {
                           this.setState({ stepNotification: "OK", dataNotification: tmp.stats });
                       }
                   }
                });
            })
            .catch((err) => {
                console.log(err, "error fetching stats");
                this.setState({
                    stepNotification: "ERROR",
                    stepDownload: "ERROR",
                    stepInvitation: "ERROR"
                });
            });
    };

    render() {
        let contentDownload;
        let contentInvitation;
        let contentNotification;

        if (this.state.stepDownload === 'LOADING') {
            contentDownload = <p>Loading</p>;
        }
        if (this.state.stepDownload === 'ERROR') {
            contentDownload = <p>Error</p>;
        }
        if (this.state.stepDownload === 'EMPTY') {
            contentDownload = <p>Aucune stats a afficher</p>;
        }
        if (this.state.stepDownload === 'OK') {
            contentDownload = <Chart
                chartType={ this.options.seriesType }
                data={ this.state.dataDownload }
                options={ this.options.optionsDown }
            />;
        }

        if (this.state.stepInvitation === 'LOADING') {
            contentInvitation = <p>Loading</p>;
        }
        if (this.state.stepInvitation === 'ERROR') {
            contentInvitation = <p>Error</p>;
        }
        if (this.state.stepInvitation === 'EMPTY') {
            contentInvitation = <p>Aucune stats a afficher</p>;
        }
        if (this.state.stepInvitation === 'OK') {
            contentInvitation = <Chart
                chartType={ this.options.seriesType }
                data={ this.state.dataInvitation }
                options={ this.options.optionsInvite }
            />;
        }
        if (this.state.stepNotification === 'LOADING') {
            contentNotification = <p>Loading</p>;
        }
        if (this.state.stepNotification === 'ERROR') {
            contentNotification = <p>Error</p>;
        }
        if (this.state.stepNotification === 'EMPTY') {
            contentNotification = <p>Aucune stats a afficher</p>;
        }
        if (this.state.stepNotification === 'OK') {
            contentNotification = <Chart
                chartType={ this.options.seriesType }
                data={ this.state.dataNotification }
                options={ this.options.optionsNotif }
            />;
        }

        return (<div className={'mainStatsView'}>
            <div className={'dateField form-group'}>
                <input type={'date'} name={ 'beginDate' } className={ 'form-control' }
                       defaultValue={ this.state.beginDate }
                       onChange={ this.handleDateChange } />
                <input type={'date'} name={ 'endDate' } className={ 'form-control' }
                       defaultValue={ this.state.endDate }
                       max={ this.state.today }
                       onChange={ this.handleDateChange } />
                <button className={ "btn btn-primary" } onClick={ () => this.loadStats(this.state.query) }>Refresh</button>
            </div>
            <div className={'detailStatsView'}>
                <h4 style={ { textAlign: 'center' } }>Téléchargements</h4>
                {contentDownload}
            </div>
            <div className={'detailStatsView'}>
                <h4 style={ { textAlign: 'center' } }>Invitation / Parrainages</h4>
                {contentInvitation}
            </div>
            <div className={'detailStatsView'}>
                <h4 style={ { textAlign: 'center' } }>Notifications</h4>
                {contentNotification}
            </div>
        </div>);
    }
};
