import React  from "react";
import PlanningManager from "src/containers/Operation/PlanningManager";
import AthleteRgpd from "./AthleteRgpd";
import TrainingManager from "./TrainingManager";

const ClubDetail = () => {
  return (
    <>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            className="nav-item nav-link active" id="nav-training-tab" data-toggle="tab" href="#nav-training" role="tab"
            aria-controls="nav-general" aria-selected="true"
          >
            Entraînment
          </a>
          <a
            className="nav-item nav-link" id="nav-training-tab" data-toggle="tab" href="#nav-planning" role="tab"
            aria-controls="nav-general" aria-selected="true"
          >
            Planning
          </a>
          <a
            className="nav-item nav-link" id="nav-rgpd-tab" data-toggle="tab" href="#nav-rgpd" role="tab"
            aria-controls="nav-text" aria-selected="false"
          >
            RGPD
          </a>
        </div>
      </nav>

      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-training" role="tabpanel" aria-labelledby="nav-training-tab">
          <TrainingManager/>
        </div>
        <div className="tab-pane fade" id="nav-planning" role="tabpanel" aria-labelledby="nav-training-tab">
          <PlanningManager/>
        </div>
        <div className="tab-pane fade" id="nav-rgpd" role="tabpanel" aria-labelledby="nav-rgpd-tab">
          <AthleteRgpd/>
        </div>
      </div>
    </>
  );
};

export default ClubDetail;
