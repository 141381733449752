import React, {useCallback, useEffect, useState} from "react";
import Modal from 'react-modal';
import { FileUploader } from "react-drag-drop-files";
import API from "src/api";

import './styles.scss';

const _ = {
  text: {
    modalTitle: 'Sélectionnez le ficher',
  },
  fileTypes: ["jpg", "jpeg", "png", "pdf"],
  modalStyle : {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }
};

function IllustrationActionBarList({onCreation, onCreated, labels, onLabelSelected}) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleChange = (files) => {
    onCreation();
    handleModalClose();
    const filesList = [];
    for(let i = 0; i < files.length; ++i) {
      filesList.push(files[i]);
    }
    return Promise.all(filesList.map((file) => Promise.all([file, file.arrayBuffer()]))).then((data) => {
      return Promise.all(data.map(([file, arrayBuffer]) => {
        const base64String = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        return API.createIllustration({filename: file.name, type: file.type, base64: base64String});
      }))
        .then(onCreated);
    });
  };

  const handleModalOpen = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen]);

  return (
    <div className={"IllustrationActionBar"}>
      <div className={"IllustrationActionBarTool"}>
        <button type="button" className="btn btn-primary" onClick={handleModalOpen}>Créer</button>
      </div>
      <IllustrationLabelFilter labels={labels} onLabelSelected={onLabelSelected}/>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        style={_.modalStyle}
        contentLabel="Example Modal"
      >
        <h2>{_.text.modalTitle}</h2>
        <FileUploader multiple handleChange={handleChange} name="file" types={_.fileTypes} />
      </Modal>
    </div>
  );
}


function IllustrationLabelFilter({labels, onLabelSelected}) {
  const [selectedLabels, setSelectedLabels] = useState([]);

  const handleChange = useCallback((event) => {
    const label = event.target.value || undefined;
    setSelectedLabels((labels) => {
      let selection;
      if (!labels.includes(label)) {
        selection = labels.concat(label);
      } else {
        selection = labels.filter((l) => l !== label);
      }
      return selection;
    })
  }, [setSelectedLabels]);

  useEffect(() => {
    onLabelSelected(selectedLabels);
  }, [onLabelSelected, selectedLabels])

  if (!Array.isArray(labels)) {
    return null;
  }

  return (
    <div className="IllustrationActionBarFilter">
      <button
        type="button" className={`btn btn-${selectedLabels.includes(undefined) ? 'info' : 'light'}`}
        onClick={handleChange}
      >
        (Vide)
      </button>
      {labels.map(({value}) => (
        <button
          key={value}
          type="button" className={`btn btn-${selectedLabels.includes(value) ? 'info' : 'light'}`}
          onClick={handleChange} value={value}
        >
          {value}
        </button>
      ))}
    </div>
  );
}

export default IllustrationActionBarList;
