import React  from "react";
import Select from 'react-select'
import './styles.scss';

const _ = {
  component: {
    customViewMapOptions: [
      // homeScreen0VideoLibrarySectionLabel: null, [null, 'Vidéos : forme à la demande']
      {
        name: 'homeScreen0VideoLibrarySectionLabel',
        label: 'VoD : Texte pour la section Les Mills (si le listage des séctions est « Commun »)',
        defaultValue: '',
        options:  [
          {value: '', label: 'Commun (Vidéo à la demande)'},
          {value: 'Vidéos : forme à la demande', label: 'Vidéos : forme à la demande'},
        ],
      },
      // homeScreen0VideoLibrarySelectionLabel: null, [null, 'Replay - Tutos - Présentations']
      {
        name: 'homeScreen0VideoLibrarySelectionLabel',
        label: 'VoD : Titre pour la section vidéos',
        defaultValue: '',
        options:  [
          {value: '', label: 'Commun (Libraires de vidéos)'},
          {value: 'Replay - Tutos - Présentations', label: 'Replay - Tutos - Présentations'},
        ],
      },
      // homeScreen0VideoCustomLibraryLabel: null, [null, 'FORME à la demande']
      {
        name: 'homeScreen0VideoCustomLibraryLabel',
        label: 'VoD : Texte pour la section Les Mills (si le listage des séctions est « cercleDeLaForme »)',
        defaultValue: '',
        options:  [
          {value: '', label: 'Commun (Vidéos de votre club)'},
          {value: 'FORME à la demande', label: 'FORME à la demande'},
        ],
      },
      // homeScreen0VideoLibraryNavigation0VideoLibraryCustomPlaylist: null, [null, 'cercleDeLaForme']
      {
        name: 'homeScreen0VideoLibraryNavigation0VideoLibraryCustomPlaylist',
        label: 'VoD : Type de liste des séctions',
        defaultValue: '',
        options:  [
          {value: '', label: 'Commun'},
          {value: 'cercleDeLaForme', label: 'cercleDeLaForme'},
        ],
      },
      // clubDetail0DisplayTabMemberShip: true, [false, true]
      {
        name: 'clubDetail0DisplayTabMemberShip',
        label: 'Afficher l’onglet « Tarifs » dans les détails du club',
        defaultValue: true,
        options:  [
          {value: true, label: 'Afficher l’onglet « Tarifs »'},
          {value: false, label: 'Masquer l’onglet « Tarifs »'}
        ],
      },
      // clubDetail0HideInvitation: false, [false, true]
      {
        name: 'clubDetail0HideInvitation',
        label: 'Masquer les boutons Invitations (Home, ClubDetail, SeanceDetail)',
        defaultValue: false,
        options:  [
          {value: false, label: 'Afficher les boutons invitations'},
          {value: true, label: 'Masquer les boutons invitations'}
        ],
      },
      // homeScreen0DisplayNutritionNavigation: false, [false, true]
      {
        name: 'homeScreen0DisplayNutritionNavigation',
        label: 'Nutrition : Donner accès à la section Nutrition',
        defaultValue: false,
        options:  [
          {value: false, label: 'Masquer la nutrition'},
          {value: true, label: 'Afficher la nutrition'}
        ],
      },
      // homeScreen0DisplayShopNavigation: false, [false, true]
      {
        name: 'homeScreen0DisplayShopNavigation',
        label: 'Accès à la boutique',
        defaultValue: false,
        options:  [
          {value: false, label: 'Masquer le magasin'},
          {value: true, label: 'Afficher le magasin'}
        ],
      },
       // homeScreen0DisplayShopAsCatalog: false, [false, true]
       {
        name: 'homeScreen0DisplayShopAsCatalog',
        label: 'Activer le mode catalogue de la boutique',
        defaultValue: false,
         options: [
          {value: true, label: 'Activer le mode catalogue'},
          {value: false, label: 'Désactiver le mode catalogue'}
        ],
      },
      // planning0PlanningExternalLinkDisplay: false, [false, true]
      {
        name: 'planning0PlanningExternalLinkDisplay',
        label: 'Planning : afficher la liste des liens externes',
        defaultValue: false,
        options: [
          {value: true, label: 'Afficher la liste des liens externes'},
          {value: false, label: 'Masquer la liste des liens externes'}
        ],
      },
      // planning0NCCCourseListHideFulledCourse: false, [false, true]
      {
        name: 'planning0NCCCourseListHideFulledCourse',
        label: 'Planning : masquer les séance sans place disponible',
        defaultValue: false,
        options: [
          {value: true, label: 'Masquer les séance sans place disponible'},
          {value: false, label: 'Afficher les séance sans place disponible'}
        ],
      },
      // planning0NCCBookingButtonHideIndicationLabel: false, [false, true]
      {
        name: 'planning0NCCBookingButtonHideIndicationLabel',
        label: 'Réservation : masquer le nombre de place disponible/prise',
        defaultValue: false,
        options: [
          {value: true, label: 'Masquer le nombre de place disponible/prise'},
          {value: false, label: 'Afficher le nombre de place disponible/prise'}
        ],
      },
      // planning0PCCBookingButtonHideWaitListIndicationLabel: false, [false, true]
      {
        name: 'planning0PCCBookingButtonHideWaitListIndicationLabel',
        label: 'Réservation : masquer le nombre de place en liste d’attente / position en file d’attente',
        defaultValue: false,
        options: [
          {value: true, label: 'Masquer le nombre de place en liste d’attente / position en file d’attente'},
          {value: false, label: 'Afficher le nombre de place en liste d’attente / position en file d’attente'}
        ],
      },
      // resamaniaUseClubConnectIntegration: false, [false, true]
      {
        name: 'resamaniaUseClubConnectIntegration',
        label: 'Activer le planning Club Connect pour Resamania',
        defaultValue: true,
        options: [
          { value: true, label: 'Activer le planning Club Connect pour Resamania' },
          { value: false, label: 'Désactiver le planning Club Connect pour Resamania' }
        ],
      },
      // deciplusForceWebviewPlanning: true, [false, true]
      {
        name: 'deciplusForceWebviewPlanning',
        label: 'Forcer l’ancien planning en webview de Deciplus',
        defaultValue: true,
        options: [
          { value: true, label: 'Forcer l’ancien planning en webview de Deciplus' },
          { value: false, label: 'Activer le nouveau planning intégré pour Deciplus'}
        ],
      },
      // planningEnableOpenSlotSection: true, [false, true]
      {
        name: 'planningEnableOpenSlotSection',
        label: 'Autoriser la réservation de créneaux dans le planning',
        defaultValue: false,
        options: [
          { value: true, label: 'Autoriser la réservation de créneaux dans le planning' },
          { value: false, label: 'Désactiver la réservation de créneaux dans le planning'}
        ],
      },
      {
        name: 'memberAreaEnabled',
        label: 'Activer la partie membre de resamania',
        defaultValue: false,
        options: [
          { value: true, label: 'Activer la partie membre de resamania' },
          { value: false, label: 'Désactiver la partie membre de resamania'}
        ],
      },
      {
        name: 'resamaniaDueEnabled',
        label: 'Activer la gestion des impayés resamania',
        defaultValue: false,
        options: [
          { value: true, label: 'Activer la gestion des impayés resamania' },
          { value: false, label: 'Désactiver la gestion des impayés resamania'}
        ],
      },
    ],
    customViewMapPalette: {
      name: 'system0PaletteColorOverload',
      defaultValue: ['#78929E', '#31596B', '#D78C83', '#9E787E']
    },
    customViewMapColors: [{
      name: 'emphasisSurface',
      label: 'Surcharger la couleurs d’un cours reservé : ',
      defaultValue: {light: '#BED3DA', dark: '#576870'},
    }, {
      name: 'selectableSurface',
      label: 'Surcharger la couleur du bouton reserver : ',
      defaultValue: {light: '#30b058', dark: '#30c058'},
    }, {
      name: 'validatedSurface',
      label: 'Surcharger la couleur du bouton annuler : ',
      defaultValue: {light: '#3058c0', dark: '#3058e0'},
    }, {
      name: 'selectablePrimeSurface',
      label: 'Surcharger la couleur du bouton rejoindre la file d’attente : ',
      defaultValue: {light: '#308080', dark: '#309090'},
    }, {
      name: 'validatedPrimeSurface',
      label: 'Surcharger la couleur du bouton quitter la file d’attente : ',
      defaultValue: {light: '#ed6801', dark: '#fd7801'},
    }],
  }
};

const ClubMobileCustomView = ({club, clubUpdater, editor}) => {
  let {customViewMap} = club;
  if(!customViewMap || typeof  customViewMap !== 'object'){
    customViewMap = {};
  }

  const handleSelectorChange = (selectedOption, event) => {
    if(_.component.customViewMapOptions.find(v => v.name === event.name).defaultValue === selectedOption.value){
      delete customViewMap[event.name]; // Default value didn't need to be stored
    } else {
      customViewMap[event.name] = selectedOption.value;
    }
    clubUpdater({...club , customViewMap});
  };

  const handleOverloadPaletteChange = (event) => {
    if(event.target.checked){
      customViewMap[_.component.customViewMapPalette.name] = _.component.customViewMapPalette.defaultValue;
    } else {
      delete customViewMap[_.component.customViewMapPalette.name];
    }
    clubUpdater({...club , customViewMap});
  }

  const handleColorPrimaryChange = (event) => {
    let tmp = { ...club };
    tmp.themeColor = event.target.value.substr(1); // remove # for themeColor
    clubUpdater(tmp);
  };

  const handleColorSecondaryChange = (event) => {
    customViewMap[_.component.customViewMapPalette.name][event.target.name] = event.target.value;
    clubUpdater({...club , customViewMap});
  }

  const handleOverloadColorChange = ({name, defaultValue}, event) => {
    if(event.target.checked){
      customViewMap[name] = defaultValue;
    } else {
      delete customViewMap[name];
    }
    clubUpdater({...club , customViewMap});
  }
  const handleColorChange = ({name, defaultValue}, event) => {
    customViewMap[name][event.target.name] = event.target.value;
    clubUpdater({...club , customViewMap});
  }

  return (
    <div className={'mobile-panel'}>
      {_.component.customViewMapOptions.map((o) => {
        const currentValue = customViewMap.hasOwnProperty(o.name) ? customViewMap[o.name] : o.defaultValue;
        const currentValueOption = o.options.find((v) => v.value === currentValue) || o.options.find((v) => v.value === o.defaultValue);
        return (
          <div key={o.name} className="mobile-select">
            <label className="mobile-select-label">{o.label}</label>
            <Select
              className="mobile-select-value" onChange={handleSelectorChange} isDisabled={editor.state}
              name={o.name} options={o.options} value={currentValueOption}
            />
          </div>
        );
      })}
      <div className="mobile-color">
        <div className="mobile-color-label">
          <label className="mobile-select-label">Surcharger la palette de couleur : </label>
          <input
            className="mobile-check-input" id="didReceiveTrainingClub" onChange={ handleOverloadPaletteChange }
            type="checkbox" name={_.component.customViewMapPalette.name}
            checked={customViewMap.hasOwnProperty(_.component.customViewMapPalette.name) } disabled={ editor.state }
          />
        </div>
        {!customViewMap.hasOwnProperty(_.component.customViewMapPalette.name) ? null : (
          <div className="mobile-color-blocks">
            <div className="mobile-color-primary">
              <label className="mobile-select-label">Couleur principal (c1) : </label>
              <input type="color" value={`#${club.themeColor}`} disabled={editor.state} onChange={handleColorPrimaryChange}/>
            </div>
            <div className="mobile-color-others">
              {customViewMap[_.component.customViewMapPalette.name].map((color, index) => (
                <div key={index} className="mobile-color-shade">
                  <label className="mobile-select-label">Couleur secondaires (c{index + 2}) : </label>
                  <input type="color" value={color} name={index} disabled={editor.state} onChange={handleColorSecondaryChange}/>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {_.component.customViewMapColors.map((o) => (
        <div key={o.name} className="mobile-color">
          <div className="mobile-color-label">
            <label className="mobile-select-label">{o.label}</label>
            <input
              className="mobile-check-input" id="overloadEmphasisSurfaceChange" onChange={(e) => handleOverloadColorChange(o, e)}
              type="checkbox" name={o.name}
              checked={customViewMap.hasOwnProperty(o.name)} disabled={ editor.state }
            />
          </div>
          {!customViewMap.hasOwnProperty(o.name) ? null : (
            <div className="mobile-color-blocks">
              <div className="mobile-color-primary">
                <label className="mobile-select-label">Couleur thème claire : </label>
                <input type="color" value={customViewMap[o.name]['light']}
                       name="light" disabled={editor.state} onChange={(e) => handleColorChange(o, e)}/>
              </div>
              <div className="mobile-color-primary">
                <label className="mobile-select-label">Couleur thème sombre : </label>
                <input type="color" value={customViewMap[o.name]['dark']}
                       name="dark" disabled={editor.state} onChange={(e) => handleColorChange(o, e)}/>
              </div>
            </div>)}
        </div>
      ))}
    </div>
  );
};

export default ClubMobileCustomView;
