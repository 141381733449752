import React, {useRef, useState} from "react";
import API from "src/api";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {createNotification} from "src/components/Toast";

import './styles.scss';

const _ = {
  text: {
    labelPlaceholder: 'Sélectionner un label',
    sharePlaceholder: 'Sélectionner les clubs pour le partage',
    urlCopied: 'URL copiée',
    savedDone: 'Modification sauvegardé',
    savedError: 'Erreur durant la sauvegarde',
  },
};

function IllustrationTable({illustrations, clubs, labels, onLabelUpdate}) {
  const handleCopy = (i) => {
    return navigator.clipboard.writeText(i.filenameURL)
      .then(() => (
        createNotification(_.text.urlCopied, {duration: 1000})
      ));
  }

  return (
    <div className="IllustrationTab">
      {illustrations.map((i) => (
        <div className="IllustrationItem" key={i.id}>
          <div className="IllustrationItemHeader">
            <h4>Illustration</h4>
            {i.id}
            <button type="button" className="btn btn-success" onClick={() => handleCopy(i)}>Copier</button>
          </div>
          <IllustrationPreview filenameURL={i.filenameURL}/>
          <IllustrationLabel illustration={i} labels={labels} onLabelUpdate={onLabelUpdate}/>
          <IllustrationShare illustration={i} clubs={clubs}/>
        </div>
      ))}
    </div>
  );
}

function IllustrationPreview({filenameURL}){
  if (filenameURL.endsWith('.pdf')) {
    return (
      <a className="IllustrationPreview" target="_blank" rel="noreferrer" href={filenameURL}>
        <iframe title={filenameURL} className="IllustrationPreview" src={filenameURL}/>
      </a>
    );
  } else { // image png, jpeg, jpg
    return (
      <a className="IllustrationPreview" target="_blank" rel="noreferrer" href={filenameURL}>
        <img className="IllustrationPreview" alt={filenameURL} src={filenameURL}/>
      </a>
    );
  }
}

function IllustrationLabel({illustration, labels, onLabelUpdate}){
  const [defaultValue] = useState(!illustration.label ? null : {value: illustration.label, label: illustration.label});

  const handleChange = (selectedOption) => {
    const label = selectedOption && selectedOption.value;
    return API.updateIllustration(null, {label}, illustration.id)
      .then(() => {
        onLabelUpdate({...illustration, label});
        createNotification(_.text.savedDone);
      })
      .catch(() => {
        createNotification(_.text.savedError, {type: 'danger'});
      });
  }

  return (
    <Creatable
      className="IllustrationClubLabel"
      onChange={handleChange}
      options={labels}
      placeholder={_.text.labelPlaceholder}
      defaultValue={defaultValue}
      isClearable
    />
  );
}

function IllustrationShare({illustration, clubs}){
  const [defaultValue] = useState(illustration.clubs
    .map((c) => ({value: c.id, label: c.name})).sort((a, b) => (a.label.localeCompare(b.label)))
  );

  const timerId = useRef(null);

  const handleChange = (selectedOptions) => {
    clearTimeout(timerId.current);
    const patch = () => {
      const clubIds = selectedOptions.map((c) => c.value);
      return API.updateIllustration(null, {clubIds}, illustration.id)
        .then(() => {
          createNotification(_.text.savedDone);
        })
        .catch(() => {
          createNotification(_.text.savedError, {type: 'danger'});
        });
    };
    timerId.current = setTimeout(patch, 800);
  }

  return (
    <Select
      className="IllustrationClubShare"
      onChange={handleChange}
      options={clubs}
      closeMenuOnSelect={false}
      isMulti
      placeholder={_.text.sharePlaceholder}
      defaultValue={defaultValue}
    />
  );
}

export default IllustrationTable;
