import React  from "react";
import './styles.scss';

const ClubStatusView = ({club, clubUpdater, editor}) => {
  const handleStateChange = (ev) => {
    let tmp = { ...club };
    tmp.state[ev.target.name] = !tmp.state[ev.target.name];
    clubUpdater(tmp);
  };

  const handleStateVideoLibraryChange = (ev) => {
    let tmp = { ...club };
    const videoLibraryGranted = Array.isArray(tmp.videoLibraryGranted) ? tmp.videoLibraryGranted : [];

    if (ev.target.name === 'isVideoLibraryLesMillActive') {
      const index = videoLibraryGranted.indexOf('lesMills');
      if (index === -1) { //add
        videoLibraryGranted.push('lesMills');
      } else {
        videoLibraryGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isVideoLibraryWafActive') {
      const index = videoLibraryGranted.indexOf('waf');
      if (index === -1) { //add
        videoLibraryGranted.push('waf');
      } else {
        videoLibraryGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isClubVODActiveClub') {
      const index = videoLibraryGranted.indexOf('clubVOD');
      if (index === -1) { //add
        videoLibraryGranted.push('clubVOD');
      } else {
        videoLibraryGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isVimeoActiveClub') {
      const index = videoLibraryGranted.indexOf('vimeo');
      if (index === -1) { //add
        videoLibraryGranted.push('vimeo');
      } else {
        videoLibraryGranted.splice(index, 1);
      }
    }
    tmp.videoLibraryGranted = [...videoLibraryGranted];
    clubUpdater(tmp);
  }

  const handleStateClientAccessGrantedChange = (ev) => {
    let tmp = { ...club };
    const clientAccessGranted = Array.isArray(tmp.clientAccessGranted) ? tmp.clientAccessGranted : [];
    if (ev.target.name === 'isClientAccessVideoLibraryLesMill') {
      const index = clientAccessGranted.indexOf('videoLibrary.lesMills');
      if (index === -1) { //add
        clientAccessGranted.push('videoLibrary.lesMills');
      } else {
        clientAccessGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isClientAccessVideoLibraryClubVOD') {
      const index = clientAccessGranted.indexOf('videoLibrary.clubVOD');
      if (index === -1) { //add
        clientAccessGranted.push('videoLibrary.clubVOD');
      } else {
        clientAccessGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isClientAccessVideoLibraryVimeo') {
      const index = clientAccessGranted.indexOf('videoLibrary.vimeo');
      if (index === -1) { //add
        clientAccessGranted.push('videoLibrary.vimeo');
      } else {
        clientAccessGranted.splice(index, 1);
      }
    }
    if (ev.target.name === 'isClientAccessVideoLibraryWaf') {
      const index = clientAccessGranted.indexOf('videoLibrary.waf');
      if (index === -1) { //add
        clientAccessGranted.push('videoLibrary.waf');
      } else {
        clientAccessGranted.splice(index, 1);
      }
    }
      if (ev.target.name === 'isClientAccessLive') {
          const index = clientAccessGranted.indexOf('videoLibrary.live');
          if (index === -1) { //add
              clientAccessGranted.push('videoLibrary.live');
          } else {
              clientAccessGranted.splice(index, 1);
          }
      }
    tmp.clientAccessGranted = [...clientAccessGranted];
    clubUpdater(tmp);
  }

  const videoLibraryGranted = Array.isArray(club.videoLibraryGranted) ? club.videoLibraryGranted : [];
  const clientAccessGranted = Array.isArray(club.clientAccessGranted) ? club.clientAccessGranted : [];

  return (
    <div className={'mainStateView'}>
      <div className="form-check">
        <input className="form-check-input" id="activeClub" onChange={ handleStateChange }
               type="checkbox" name={'active'}
               defaultChecked={ club.state.active } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="activeClub">
          Club Active
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="disabledClub" onChange={ handleStateChange }
               type="checkbox" name={'disabled'}
               defaultChecked={ club.state.disabled } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="disabledClub">
          Club Disabled
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="billableClub" onChange={ handleStateChange }
               type="checkbox" name={'billable'}
               defaultChecked={ club.state.billable } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="billableClub">
          Club Billable
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="sleepingClub" onChange={ handleStateChange }
               type="checkbox" name={'sleeping'}
               defaultChecked={ club.state.sleeping } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="sleepingClub">
          Club Sleep
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="sendInvoiceClub" onChange={ handleStateChange }
               type="checkbox" name={'sendInvoice'}
               defaultChecked={ club.state.sendInvoice } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="sendInvoiceClub">
          Club Send Invoice
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="didSendCodesClub" onChange={ handleStateChange }
               type="checkbox" name={'didSendCodes'}
               defaultChecked={ club.state.didSendCodes } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="didSendCodesClub">
          Club did Send Codes
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isVisualUploadClub" onChange={ handleStateChange }
               type="checkbox" name={'isVisualUpload'}
               defaultChecked={ club.state.isVisualUpload } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isVisualUploadClub">
          Club is Visual Upload
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="bannerEnabledClub" onChange={ handleStateChange }
               type="checkbox" name={'bannerEnabled'}
               defaultChecked={ club.state.bannerEnabled } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="bannerEnabledClub">
          Club enable Banner
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClubReminderClub" onChange={ handleStateChange }
               type="checkbox" name={'isClubReminder'}
               defaultChecked={ club.state.isClubReminder } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClubReminderClub">
           is Club Reminder
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="didSendContractClub" onChange={ handleStateChange }
               type="checkbox" name={'didSendContract'}
               defaultChecked={ club.state.didSendContract } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="didSendContractClub">
          did Send Contract
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isProductEnabledClub" onChange={ handleStateChange }
               type="checkbox" name={'isProductEnabled'}
               defaultChecked={ club.state.isProductEnabled } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isProductEnabledClub">
          is Product Enabled
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isPartnersPictureClub" onChange={ handleStateChange }
               type="checkbox" name={'isPartnersPicture'}
               defaultChecked={ club.state.isPartnersPicture } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isPartnersPictureClub">
          is Partners Picture
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="didReceiveContractClub" onChange={ handleStateChange }
               type="checkbox" name={'didReceiveContract'}
               defaultChecked={ club.state.didReceiveContract } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="didReceiveContractClub">
          Club Receive Contract
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="didReceiveTrainingClub" onChange={ handleStateChange }
               type="checkbox" name={'didReceiveTraining'}
               defaultChecked={ club.state.didReceiveTraining } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="didReceiveTrainingClub">
          Club Receive Back-Office Training
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="showAllRoomAtLaunchClub" onChange={ handleStateChange }
               type="checkbox" name={'showAllRoomAtLaunch'}
               defaultChecked={ club.state.showAllRoomAtLaunch } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="showAllRoomAtLaunchClub">
          show All Room At Launch
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="showAllClubsAtLaunchClub" onChange={ handleStateChange }
               type="checkbox" name={'showAllClubsAtLaunch'}
               defaultChecked={ club.state.showAllClubsAtLaunch } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="showAllClubsAtLaunchClub">
          show All Clubs At Launch
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="filterCoursesByTrainerClub" onChange={ handleStateChange }
               type="checkbox" name={'filterCoursesByTrainer'}
               defaultChecked={ club.state.filterCoursesByTrainer } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="filterCoursesByTrainerClub">
          filter Courses By Trainer
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="showAllClubsAtPlanningLaunchClub" onChange={ handleStateChange }
               type="checkbox" name={'showAllClubsAtPlanningLaunch'}
               defaultChecked={ club.state.showAllClubsAtPlanningLaunch } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="showAllClubsAtPlanningLaunchClub">
          show All Clubs At Planning Launch
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isVideoLibraryLesMillActiveClub" onChange={ handleStateVideoLibraryChange }
               type="checkbox" name={'isVideoLibraryLesMillActive'}
               defaultChecked={ videoLibraryGranted.indexOf('lesMills') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isVideoLibraryLesMillActiveClub">
          {'\uD83C\uDF9E'} Autorise l'accès aux videos "Les Mills" pour les adhérents du club
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" id="isVideoLibraryWafActiveClub" onChange={ handleStateVideoLibraryChange }
               type="checkbox" name={'isVideoLibraryWafActive'}
               defaultChecked={ videoLibraryGranted.indexOf('waf') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isVideoLibraryWafActive">
          {'\uD83C\uDF9E'} Autorise l'accès aux videos "We are Fitness" pour les adhérents du club
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClubVODActiveClub" onChange={ handleStateVideoLibraryChange }
               type="checkbox" name={'isClubVODActiveClub'}
               defaultChecked={ videoLibraryGranted.indexOf('clubVOD') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClubVODActiveClub">
          {'\uD83C\uDF9E'} Autorise l'accès aux videos du club pour les adhérents du club
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isVimeoActiveClub" onChange={ handleStateVideoLibraryChange }
               type="checkbox" name={'isVimeoActiveClub'}
               defaultChecked={ videoLibraryGranted.indexOf('vimeo') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isVimeoActiveClub">
          {'\uD83C\uDF9E'} Autorise l'accès aux videos "Viméo" pour les adhérents du club
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClientAccessVideoLibraryLesMillActive"
               onChange={ handleStateClientAccessGrantedChange } type="checkbox" name={'isClientAccessVideoLibraryLesMill'}
               defaultChecked={ clientAccessGranted.indexOf('videoLibrary.lesMills') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClientAccessVideoLibraryLesMill">
          {'\uD83C\uDFDF'} Donne accès à tous les clients du club aux videos "Les Mills" sans abonnement
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClientAccessVideoLibraryLesMillActive"
               onChange={ handleStateClientAccessGrantedChange } type="checkbox" name={'isClientAccessVideoLibraryClubVOD'}
               defaultChecked={ clientAccessGranted.indexOf('videoLibrary.clubVOD') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClientAccessVideoLibraryClubVOD">
          {'\uD83C\uDFDF'} Donne accès à tous les clients du club aux videos "VOD du club" sans abonnement
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClientAccessVideoLibraryVimeo"
               onChange={ handleStateClientAccessGrantedChange } type="checkbox" name={'isClientAccessVideoLibraryVimeo'}
               defaultChecked={ clientAccessGranted.indexOf('videoLibrary.vimeo') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClientAccessVideoLibraryVimeo">
          {'\uD83C\uDFDF'} Donne accès à tous les clients du club aux videos "Vimeo" sans abonnement
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="isClientAccessVideoLibraryWaf"
               onChange={ handleStateClientAccessGrantedChange } type="checkbox" name={'isClientAccessVideoLibraryWaf'}
               defaultChecked={ clientAccessGranted.indexOf('videoLibrary.waf') !== -1 } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="isClientAccessVideoLibraryWaf">
          {'\uD83C\uDFDF'} Donne accès à tous les clients du club aux videos "We are Fitness" sans abonnement
        </label>
      </div>

      <div className="form-check">
          <input className="form-check-input" id="isClientAccessLive"
                 onChange={ handleStateClientAccessGrantedChange } type="checkbox" name={'isClientAccessLive'}
                 defaultChecked={ clientAccessGranted.indexOf('videoLibrary.live') !== -1 } disabled={ editor.state } />
          <label className="form-check-label" htmlFor="isClientAccessLive">
              {'\uD83C\uDFDF'} Donne accès à tous les clients du club aux Lives
          </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="resaQrcodeEnabled" onChange={ handleStateChange }
               type="checkbox" name={'resaQrcodeEnabled'}
               defaultChecked={ club.state.resaQrcodeEnabled } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="resaQrcodeEnabled">
          Resamania access card
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" id="inbodyEnabled" onChange={ handleStateChange }
               type="checkbox" name={'inbodyEnabled'}
               defaultChecked={ club.state.inbodyEnabled } disabled={ editor.state } />
        <label className="form-check-label" htmlFor="inbodyEnabled">
          Intégration InBody
        </label>
      </div>
    </div>);
};

export default ClubStatusView;
