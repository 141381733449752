import React  from "react";
import Select from 'react-select'
import './styles.scss';

const _ = {
  component: {
    customViewMapOptions: [
      // planning0LimitClubFilterCount: 3, [1...30]
      {
        name: 'planning0LimitClubFilterCount',
        label: 'Changer le nombre maximal de club sélectionnable pour le filtrage du planning',
        defaultValue: 3,
        options: new Array(30).fill(0).map((v, i) => ({value: i + 1, label: i + 1})),
      },
      // planning0DisplayAddAllClientRelatedCompaniesButton: false, [false, true]
      {
        name: 'planning0DisplayAddAllClientRelatedCompaniesButton',
        label: 'Activer le bouton d’ajout de tous les clubs pour l’utilisateur dans le planning',
        defaultValue: false,
        options: [
          { value: true, label: 'Activer le bouton d’ajout de tous les clubs pour l’utilisateur dans le planning' },
          { value: false, label: 'Désactiver le bouton d’ajout de tous les clubs pour l’utilisateur dans le planning' }
        ],
      }
    ],
  }
};


const ClubCompaniesView = ({club, clubUpdater, editor}) => {
  let {countRelatedCompanies, companiesCustomViewMap} = club;
  if(!companiesCustomViewMap || typeof  companiesCustomViewMap !== 'object'){
    companiesCustomViewMap = {};
  }

  const handleSelectorChange = (selectedOption, event) => {
    if(_.component.customViewMapOptions.find(v => v.name === event.name).defaultValue === selectedOption.value){
      delete companiesCustomViewMap[event.name]; // Default value didn't need to be stored
    } else {
      companiesCustomViewMap[event.name] = selectedOption.value;
    }
    clubUpdater({...club , companiesCustomViewMap});
  };

  return (
    <div className="companies-panel">
      <h4>Ce groupe contient {countRelatedCompanies} clubs</h4>
      {_.component.customViewMapOptions.map((o) => {
        const currentValue = companiesCustomViewMap.hasOwnProperty(o.name) ? companiesCustomViewMap[o.name] : o.defaultValue;
        const currentValueOption = o.options.find((v) => v.value === currentValue) || o.options.find((v) => v.value === o.defaultValue);
        return (
          <div key={o.name} className="companies-select">
            <label className="companies-select-label">{o.label}</label>
            <Select
              className="companies-select-value" onChange={handleSelectorChange} isDisabled={editor.state}
              name={o.name} options={o.options} value={currentValueOption}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ClubCompaniesView;
