import React, { useState } from "react";
import './styles.scss';

const ClubBookingView = ({club, clubUpdater, editor}) => {
  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  let [ channels, channelsUpdate ] = useState( [ ...club.booking.channels || '' ] );
  let [ channelsMobile, channelsMobileUpdate ] = useState( [ ...club.booking.channelsMobile || '' ] );
  let [ rooms, roomsUpdate ] = useState( [ ...club.booking.classesRooms ] );
  let [ coachTimes, coachTimesUpdate ] = useState( [ ...club.booking.coachTimes ] );
  let [ openingTimes, openingTimesUpdate ] = useState( [ ...club.booking.openingTimes ] );
  let [ receptionTimes, receptionTimesUpdate ] = useState( [ ...club.booking.receptionTimes ] );
  let [ load, loadUpdate ] = useState( false );

  if (!load) {
    if (club.booking.channels.length > 0) {
      channelsUpdate([ ...club.booking.channels ] );
    }
    if (club.booking.classesRooms.length > 0) {
      roomsUpdate([ ...club.booking.classesRooms ] );
    }
    if (club.booking.coachTimes.length > 0) {
      coachTimesUpdate([ ...club.booking.coachTimes ] );
    }
    if (club.booking.openingTimes.length > 0) {
      openingTimesUpdate([ ...club.booking.openingTimes ] );
    }
    if (club.booking.receptionTimes.length > 0) {
      receptionTimesUpdate([ ...club.booking.receptionTimes ] );
    }
    loadUpdate(true);
  }

  const handleArrayChange = (ev) => {
    let tmp = { ...club };
    let index = ev.target.name.split('-');
    tmp.booking[index[0]][index[1]] = ev.target.value;
    clubUpdater(tmp);
  };
  const handleStateChange = (ev) => {
    let tmp = { ...club };
    tmp.state[ev.target.name] = !tmp.state[ev.target.name];
    clubUpdater(tmp);
  };
  const handleChange = (ev) => {
    let tmp = { ...club };
    tmp.booking[ev.target.name] = ev.target.value;
    clubUpdater(tmp);
  };

  const addRooms = () => {
    roomsUpdate([ ...rooms, ""]);
  };
  const delRooms = () => {
    let room = rooms;
    room.pop();
    roomsUpdate([ ...room ]);
  };
  const addChannel = () => {
    channelsUpdate([ ...channels, ""]);
  };
  const delChannel = () => {
    let chan = channels;
    chan.pop();
    channelsUpdate([ ...chan ]);
  };
  const addMobChannel = () => {
    channelsMobileUpdate([ ...channelsMobile, ""]);
  };
  const delMobChannel = () => {
    let chan = channelsMobile;
    chan.pop();
    channelsMobileUpdate([ ...chan ]);
  };

  return (<>
    <nav>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <a className="nav-item nav-link active" id="nav-booking-array-tab" data-toggle="tab" href="#nav-booking-array" role="tab"
           aria-controls="nav-text" aria-selected="false">Horaires / Channels</a>
        <a className="nav-item nav-link" id="nav-booking-general-tab" data-toggle="tab" href="#nav-booking-general" role="tab"
           aria-controls="nav-general" aria-selected="true">Autres infos Booking</a>
      </div>
    </nav>

    <div className="tab-content" id="nav-tabContent">

      <div className="tab-pane fade show active" id="nav-booking-array" role="tabpanel" aria-labelledby="nav-booking-array-tab">
        <div className={ 'firstTier' }>
          <br/>
          <h5>Channels of Club :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Channels</span>
            </div>
            { channels.map((elem, key) => {
              return (
                  <input type="text" className="form-control" key={ key } defaultValue={ elem }
                         name={ `channels-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
            <div className="input-group-append">
              <span className="input-group-text" onClick={ addChannel }>Add one</span>
              <span className="input-group-text" onClick={ delChannel }>Delete last</span>
            </div>
          </div>

          <br/>
          <h5>Channels Mobile of Club :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Channels Mobile</span>
            </div>
            { channelsMobile.map((elem, key) => {
              return (
                  <input type="text" className="form-control" key={ key } defaultValue={ elem }
                         name={ `channelsMobile-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
            <div className="input-group-append">
              <span className="input-group-text" onClick={ addMobChannel }>Add one</span>
              <span className="input-group-text" onClick={ delMobChannel }>Delete last</span>
            </div>
          </div>

          <br/>
          <h5>Rooms of Club :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Rooms</span>
            </div>
            { rooms.map((elem, key) => {
              return (
                  <input type="text" className="form-control" key={ key } defaultValue={ elem }
                         name={ `classesRooms-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
            <div className="input-group-append">
              <span className="input-group-text" onClick={ addRooms }>Add one</span>
              <span className="input-group-text" onClick={ delRooms }>Delete last</span>
            </div>
          </div>

          <br/>
          <h5>Coach present at :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Coach Times</span>
            </div>
            { coachTimes.map((elem, key) => {
              return (
                  <input
                      type="text" className="form-control" key={ key } defaultValue={ elem } placeholder={ days[key] }
                      name={ `coachTimes-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
          </div>

          <br/>
          <h5>Club open at :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Opening Times</span>
            </div>
            { openingTimes.map((elem, key) => {
              return (
                  <input
                      type="text" className="form-control" key={ key } defaultValue={ elem } placeholder={ days[key] }
                      name={ `openingTimes-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
          </div>

          <br/>
          <h5>Reception at :</h5>
          <div className="input-group input-group-sm ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Reception Times</span>
            </div>
            { receptionTimes.map((elem, key) => {
              return (
                  <input
                      type="text" className="form-control" key={ key } defaultValue={ elem } placeholder={ days[key] }
                      name={ `receptionTimes-${key}` } onChange={ handleArrayChange } disabled={ editor.state } />
              );
            }) }
          </div>
        </div>
      </div>

      <div className="tab-pane fade show" id="nav-booking-general" role="tabpanel" aria-labelledby="nav-booking-general-tab">

        <div className={ 'stateTier' }>
          <div className={"form-check"}>
            <input type={'checkbox'} className="form-check-input" defaultChecked={ club.state.bookingTab }
                   name={ 'bookingTab' } id={"bookingTabEnabler"} onChange={ handleStateChange } disabled={ editor.state } />
            <label className="form-check-label" htmlFor="bookingTabEnabler">Enable Booking Tab</label>
          </div>
          <div className={"form-check"}>
            <input type={'checkbox'} className="form-check-input" defaultChecked={ club.state.bookingLog }
                   name={ 'bookingLog' } id={"bookingLogEnabler"} onChange={ handleStateChange } disabled={ editor.state } />
            <label className="form-check-label" htmlFor="bookingLogEnabler">Enable Booking Log</label>
          </div>
          <div className={"form-check"}>
            <input type={'checkbox'} className="form-check-input" defaultChecked={ club.state.bookingBilan }
                   name={ 'bookingBilan' } id={"bookingBilanEnabler"} onChange={ handleStateChange } disabled={ editor.state } />
            <label className="form-check-label" htmlFor="bookingBilanEnabler">Enable Booking Bilan</label>
          </div>
          <div className={"form-check"}>
            <input type={'checkbox'} className="form-check-input" defaultChecked={ club.state.bookingInternal }
                   name={ 'bookingInternal' } id={"bookingInternalEnabler"} onChange={ handleStateChange } disabled={ editor.state } />
            <label className="form-check-label" htmlFor="bookingInternalEnabler">Enable Internal Booking</label>
          </div>
          <div className={"form-check"}>
            <input type={'checkbox'} className="form-check-input" defaultChecked={ club.state.bookingExternal }
                   name={ 'bookingExternal' } id={"bookingExternalEnabler"} onChange={ handleStateChange } disabled={ editor.state } />
            <label className="form-check-label" htmlFor="bookingExternalEnabler">Enable External Booking</label>
          </div>
        </div>

        {/* ____________ Mail A BAPTISTE ou es ce que le gestionnaire de club renseigne ces champs, ou sont ils utilisé_______________   */}
        <div className={ 'mainBookingView' }>
          <div className={ 'secondTier' }>
        {/*    <div>link text</div>*/}
        {/*    <div>homeLink text</div>*/}
            <div className="form-group">
              <label htmlFor="homeTitleInput">Home Title</label>
              <input type="text" className="form-control" id="homeTitleInput" disabled={ editor.state }
                     defaultValue={ club.booking.homeTitle } name={ 'homeTitle' } onChange={ handleChange }
                     aria-describedby="Home Title" placeholder="Home Title" />
            </div>
        {/*    <div>bilanLink text</div>*/}
          </div>
          <div className={ 'thirdTier' }>
            <div className="form-group">
              <label htmlFor="bilanTitleInput">Bilan Title</label>
              <input type="text" className="form-control" id="bilanTitleInput" disabled={ editor.state }
                     defaultValue={ club.booking.bilanTitle } name={ 'bilanTitle' } onChange={ handleChange }
                     aria-describedby="Bilan Title" placeholder="Bilan Title" />
            </div>
        {/*    <div>remindBooked</div>*/}
        {/*    <div>planningHomeLink</div>*/}
        {/*    <div>initialRoom</div>*/}
        {/*    <div>showBookingCount</div>*/}
        {/*    <div>remindCalendarEvent</div>*/}
          </div>
        </div>
      </div>

    </div>
  </>);
};

export default ClubBookingView;