/* index API */
import auth from "./Oauth";
import club from './Club';
import admin from "./Admin";
import stats from "./Stats";
import illustration from "./Illustration";
import document from "./Documents";
import shopCommand from "./ShopCommand";
import shopRefund from "./ShopRefund";
import athlete from "./Athlete";
import exercise from "src/api/Exercise";
import workout from "src/api/Workout";
import workoutPack from "src/api/WorkoutPack";
import coursTemplate from "src/api/CoursTemplate";

let API = {
    // Oauth
    signIn: ({email, password}) => auth.POST('session/',{email, password}),
    signRefresh: ({ refresh }) => auth.POST_REFRESH('session/refresh',{ refreshToken: refresh }),
    signOut: () => auth.DELETE(),
    iAmAuth: () => auth.GET(),
    // Club (club_first)
    loadClub: (query) => club.GET('club', query),
    detailClub: (query, id) => club.GETBYID('club', query, id),
    verifUsername: (query) => club.GETUSERNAME('club', query),
    getClubActivity: (query) => club.GETACTIVITY('club', query),
    updateClub: (query, body, id) => club.PATCH('club', body, query, id),
    createClub: (body, query) => club.POST('club', body, query),
    createClubChargebeeCustomer: (clubId, body) => club.POSTChargebeeCustomer('club', body, clubId),
    deleteClub: (query) => club.DELETE('club', query),
    // Stats
    getDownload: (query) => stats.GETDOWNLOAD('telechargements/stats', query),
    getInvitation: (query) => stats.GETINVITATION('invitations/stats', query),
    getNotification: (query) => stats.GETNOTIFICATION('notifications/stats', query),
    // Admin
    loadAdmin: (query) => admin.GET('admin', query),
    detailAdmin: (query, id) => admin.GETBYID('admin', query, id),
    updateAdmin: (query, body, id) => admin.PATCH('admin', body, query, id),
    createAdmin: ({email, password}, query) => admin.POST('admin', {email, password}, query),
    deleteAdmin: (query) => admin.DELETE('admin', query),
    // Illustrations
    createIllustration: (body, query) => illustration.POST(body, query),
    loadIllustration: (query) => illustration.GET(query),
    detailIllustration: (query, id) => illustration.GETBYID(query, id),
    updateIllustration: (query, body, id) => illustration.PATCH(body, query, id),
    deleteIllustration: (query) => illustration.DELETE(query),
    // Documents
    createDocument: (body, query) => document.POST('documents', body, query),
    deleteDocument: (query) => document.DELETE('documents', query),
    // Shop
    searchSopCommand: (query) => shopCommand.GET('shopCommand', query),
    createShopRefund: (body, query) => shopRefund.POST('shopRefund', body, query),
    searchShopRefund: (query) => shopRefund.GET('shopRefund', query),
    patchShopRefund: (id, body, query) => shopRefund.PATCH('shopRefund', id, body, query),
    deleteShopRefund: (id, query) => shopRefund.DELETE('shopRefund', query, id),
    // Athlete
    searchAthlete: (query) => athlete.GET('athlete', query),
    anonymizeAthlete: (id, body, query) => athlete.POST('rgpdRequest', id, body, query),
    // Exercise
    searchExercise: (query) => exercise.GET('exercise', query),
    deleteExercise: (id) => exercise.DELETE('exercise', id),
    // Workout
    searchWorkout: (query) => workout.GET('workout', query),
    deleteWorkout: (id) => workout.DELETE('workout', id),
    // WorkoutPack
    searchWorkoutPack: (query) => workoutPack.GET('workoutPack', query),
    deleteWorkoutPack: (id) => workoutPack.DELETE('workoutPack', id),
    // CoursTemplate
    searchCoursTemplate: (query) => coursTemplate.GET('coursTemplate', query),
    deleteCoursTemplate: (id) => coursTemplate.DELETE('coursTemplate', id),
    cloneCoursTemplate: (id, clubId) => coursTemplate.POST('coursTemplate', id, clubId),
};

export default API;
