import React, {useState} from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import API from "src/api";

import "src/containers/Operation/AthleteRgpd/styles.scss";

const _ = {
  text: {
    searchButton: 'Recherche l’athlete',
    noAthleteFound: 'Aucun athlete trouvé avec cet email',
    athleteFetchError: 'Erreur inconnue au bataillon capitaine',
    anonymizeButton: 'Anonymiser définitivement l’athlete',
    anonymizeConfirm: 'Êtes-vous sûrs ? Ceci sera définif !',
  }
}

function AthleteRgpd(){
  const [searchEmail, searchEmailSet] = useState('');
  const [athleteData, athleteDataSet] = useState();

  const handleEmailChange = (event) => {
    searchEmailSet(event.target.value);
  };

  const handleSearch = () => {
    athleteDataSet(null);
    API.searchAthlete(`email=${searchEmail}`)
      .then((response) => {
        if(!response || response.statusCode !== 200) {
          throw response;
        }
        const results = response.body;
        if(results.length <= 0){
          return athleteDataSet(_.text.noAthleteFound);
        }
        return athleteDataSet(results[0]);
      })
      .catch(() => {
          athleteDataSet(_.text.athleteFetchError);
      });
  };

  const handleAnonymize = () => {
    if (window.confirm(_.text.anonymizeConfirm)) {
      athleteDataSet(null);
      API.anonymizeAthlete(athleteData.id).then(handleSearch);
    }
  };

  return (
    <div className="rgpdMain">
      <div className="rgpdForm">
        <h3>Trouvez l’Ahtlete à anonymiser</h3>
        <div className="form-group">
          <label htmlFor="EmailInput">Email</label>
          <input
            type="email" className="form-control" id="EmailInput" defaultValue={ searchEmail }
            name={'email'} onChange={ handleEmailChange } aria-describedby="Email de l'athlete" placeholder="email"
          />
        </div>
        <button onClick={ handleSearch } type="button" className="btn btn-primary">{_.text.searchButton}</button>
      </div>
      <div className="rgpdResult">
        <AthleteData athleteData={athleteData}>
          <button onClick={ handleAnonymize } type="button" className="btn btn-danger">{_.text.anonymizeButton}</button>
        </AthleteData>
      </div>
    </div>
  );
}

const AthleteData = ({athleteData, children}) => {
  if(athleteData === null){
    return (
      <div className="rgpdLoading">
        <p>Traitement...</p>
        <PulseLoader
          size={50}
          color={"#17a2b8"}
        />
      </div>
    );
  }
  if(typeof athleteData === 'object'){
    return (
      <>
        {Object.keys(athleteData).map((k) => {
          let value = athleteData[k];
          if(Array.isArray(athleteData[k])){
            value = `${athleteData[k].length} (${athleteData[k].map(v => v.clubName).join(', ')})`
          }
          return (
            <span key={k}>
              <span className="rgpdAthleteKey">{k} : </span>
              <span className="rgpdAthleteValue">{value}</span>
            </span>
          );
        })}
        {children}
      </>
    );
  }
  return <h4>{athleteData}</h4>;
}

export default AthleteRgpd;
