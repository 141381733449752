import React, { useState } from "react";
import './styles.scss';
import AdminSelect from "../../components/AdminSelect";
import API from "../../api";
import ActionBarList from "../../components/ActionBarList";
import Modal from "../../components/Modal";

const Admin = () => {
    const type = 'admin';
    const initialRetour = {show: false, wait:false, icon: '', title: '', text: '', callback: () => {} };
    const Field = [
        { name: 'select', value: 'Select' },
        { name: "createdAt", value: "Created At" },
        { name: "corp/name", value: "Corp / Name" },
        { name: "metier", value: "Jobs" },
        { name: "email", value: 'Email'}
    ];
    const FieldModal = [
        {name: "avatar", value: "Avatar"},
    ];
    let [ Admins, AdminsUpdate ] = useState([]);
    let [ load, loadUpdate ] = useState(false);
    let [ count, countUpdate ] = useState(0);
    let [ criteria, criteriaUpdate ] = useState("");
    let [ uploadFile, setUploadFile ] = useState({ name: '', prefix: '', file: {} });
    let [ selector, selectorUpdate ] = useState(FieldModal[0].name);
    let [ selectedAdmin, selectAdmin ] = useState([]);
    let [ retour, retUpdate ] = useState(initialRetour);

    const search = (query) => {
        if (criteria.length > 0) {
            query = `query=${criteria}`;
        }
        API.loadAdmin(query).then((admins) => {
            let tmp = admins.body;
            AdminsUpdate(tmp.admins);
            countUpdate(tmp.count);
        }).catch((er) => {
            AdminsUpdate([]);
            countUpdate(0);
            alert(er.message);
        });
    };
    const handleChange = (ev) => {
        if (ev.target.name === 'search') {
            criteriaUpdate(ev.target.value);
        }
    };
    if (!load) {
        search(criteria);
        loadUpdate(true);
    }
    const selectAdmins = (ev) => {
        selectedAdmin.push(ev.target.name);
        selectAdmin(selectedAdmin);
    };

    return (<div>
        <div className={"headerAdmin"}>
            <ActionBarList
                count={ count } retour={ retour} type={ type } retUpdate={ retUpdate } initialRetour={ initialRetour }
                handleChange={ handleChange } search={ search } selectedDocument={ selectedAdmin } />
        </div>
        <div>
            <table className="table">
                <thead>
                <tr>
                    {Field.map((el, key) => {
                        return (
                            <th scope="col" key={key} >{el.value}</th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {Admins.map((el, key) => {
                    return (
                        <tr key={key}>
                            <AdminSelect admin={el} selectAdmin={ selectAdmins }/>
                        </tr>);
                })}
                </tbody>
            </table>
        </div>
        <Modal selector={ selector } FieldModal={ FieldModal } selectorUpdate={ selectorUpdate }
               setUploadFile={ setUploadFile } retUpdate={ retUpdate } uploadFile={uploadFile} search={ search } type={'admin'}/>
    </div>);
};

export default Admin;
