import React, {useEffect, useRef, useState} from 'react';
import {PulseLoader, PacmanLoader} from 'react-spinners';
import API from "src/api";
import {createNotification} from "src/components/Toast";

import "./styles.scss";

const _ = {
  text: {
    senderClub: 'Club d’origine',
    recipientClub: 'Club destinairaire',
    clubSelectionTitle: 'ObjectId',
    missingClub: 'Club non trouvé 🤔',
    exercise: 'Exercices',
    workout: 'Séance',
    workoutPack: 'Programme',
  }
}

function TrainingManager() {
  const [searchType, setSearchType] = useState();
  const [selectedId, setSelectedId] = useState([]);
  const [isPerforming, setPerforming] = useState();
  const recipientClubId = useRef(null);

  const handleTypeChange = (event) => {
    const value = event.target.id;
    setSearchType(value);
  };

  const handleDeletion = () => {
    if (selectedId.length <= 0) {
      return createNotification('Aucune donnée sélectionnée', {type: 'danger'});
    }
    if(window.confirm(`Supprimer ${selectedId.length} ${_.text[searchType]} ?`)){
      return deleteSelected(searchType, selectedId);
    }
  };

  const deleteSelected = async (searchType, selectedId) => {
    let count = 0;
    const updateMessage = () => setPerforming(`Suppression en cours : ${count}/${selectedId.length}`);
    updateMessage();
    try {
      for (const elementId of selectedId) {
        if (searchType === 'exercise') {
          await API.deleteExercise(elementId);
        } else if (searchType === 'workout') {
          await API.deleteWorkout(elementId);
        } else { // workoutPack
          await API.deleteWorkoutPack(elementId);
        }
        ++count;
        updateMessage();
      }
    } catch (err) {
      createNotification('Erreur durant la suppression', {type: 'danger'});
    }
    createNotification('Suppression terminée');
    setPerforming();
  };

  const handleClubIdChange = (clubId) => {
    recipientClubId.current = clubId;
  }

  return (
    <div className="trainingManagerMain">
      {isPerforming ? (
        <div className="body-performing">
          <PacmanLoader size={50} css={{margin: "0 100px 50px 0"}}/>
          <div className="performing-text">{isPerforming}</div>
        </div>
      ) : (
        <div className="body">
          <ClubSelection
            title={_.text.senderClub} searchType={searchType}
            selectedId={selectedId} setSelectedId={setSelectedId}
          />
          <ClubSelection title={_.text.recipientClub} searchType={searchType} onClubIdChange={handleClubIdChange}/>
        </div>
      )}
      <div className="footer">
        <button type="button" className="btn btn-danger" onClick={handleDeletion} disabled={isPerforming}>
          Supprimer
        </button>
        {isPerforming ? (
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className="btn btn-info" id="exercise">
              <input type="radio" name="options" autoComplete="off"/>
              {_.text[searchType]}
            </label>
          </div>
        ) : (
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className="btn btn-info" onClick={handleTypeChange} id="exercise">
              <input type="radio" name="options" autoComplete="off"/>
              {_.text.exercise}
            </label>
            <label className="btn btn-info" onClick={handleTypeChange} id="workout">
              <input type="radio" name="options" autoComplete="off" disabled={isPerforming}/>
              {_.text.workout}
            </label>
            <label className="btn btn-info" onClick={handleTypeChange} id="workoutPack">
              <input type="radio" name="options" autoComplete="off" disabled={isPerforming}/>
              {_.text.workoutPack}
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

function ClubSelection({title, searchType, selectedId, setSelectedId, onClubIdChange}){
  const [clubId, setClubId] = useState('');
  // const [clubId, setClubId] = useState(title === _.text.senderClub ? 'Capdee9pWS' : 'OLaqBEHslw'); // for test
  const [clubData, setClubData] = useState({});

  useEffect(() => {
    onClubIdChange?.();
    if (typeof clubId !== 'string' || ![10, 24].includes(clubId.length)) {
      setClubData({name: clubId.length > 0 ? _.text.missingClub : ''});
      return;
    }
    API.detailClub(null, clubId)
      .then((response) => {
        if (response.statusCode === 200 && response?.body?.club) {
          setClubData(response.body.club);
          onClubIdChange(response.body.club._id);
        } else {
          setClubData({name: _.text.missingClub});
        }
      })
      .catch(() => {});
  }, [clubId, onClubIdChange]);

  const handleClubIdChange = (event) => {
    setClubId(event.target.value);
  };

  return (
    <div className="trainingManager-clubSelection">
      <h4>{title}</h4>
      <div className="form-group">
        <label htmlFor="EmailInput">ObjectId du club</label>
        <input
          type="text" className="form-control" defaultValue={ clubId } name={'clubObjectId'}
          onChange={ handleClubIdChange } aria-describedby="ObjectId du club" placeholder="clubObjectId"
        />
      </div>
      <h5>{clubData?.name}</h5>
      <ClubDataType
        searchType={searchType} clubId={clubData?._id} selectedId={selectedId} setSelectedId={setSelectedId}
      />
    </div>
  );
}

function ClubDataType({searchType, clubId, selectedId, setSelectedId}) {
  const hideSelect = typeof setSelectedId !== 'function';
  const checkboxRef = React.useRef();
  const [elements, setElements] = useState(null);

  useEffect(() => {
    setSelectedId?.([]);
    setElements(null);
    if (!searchType || !clubId) {
      return;
    }
    let query = null;
    if (searchType === 'exercise') {
      query = API.searchExercise(`clubId=${clubId}`);
    } else if (searchType === 'workout') {
      query = API.searchWorkout(`clubId=${clubId}`);
    } else { // workoutPack
      query = API.searchWorkoutPack(`clubId=${clubId}`);
    }
    query
      .then((response) => {
        const data = response.body;
        data.sort((a, b) => a.name.localeCompare(b.name));
        setElements(data.map((e) => {
          const keyType = {
            'exercise': 'exercices',
            'workout': 'entrainement',
            'workoutPack': 'packs',
          }[searchType];
          e.BOLink = `https://clubconnect.masalledesport.com/club/${clubId}/${keyType}/app/${e.id}`;
          return e;
        }));
      })
      .catch(() => {});
  }, [searchType, clubId, setSelectedId]);

  if (!searchType || !clubId) {
    return null;
  }

  if (!Array.isArray(elements)) {
    return <PulseLoader/>;
  }

  const handleCheckBox = (event) => {
    const id = event.target.id;
    if (selectedId.includes(id)) {
      checkboxRef.current.indeterminate = true;
      setSelectedId(selectedId.filter((v) => (v !== id)));
    } else {
      checkboxRef.current.indeterminate = selectedId.length + 1 < elements.length;
      setSelectedId([...selectedId, id]);
    }
  };

  const handleAllCheckBox = () => {
    if (selectedId.length > 0) {
      setSelectedId([]);
    } else {
      setSelectedId(elements.map((e) => e.id));
    }
    checkboxRef.current.indeterminate = false;
  }

  return (
    <div className="trainingManager-elementData">
      <div>{`${_.text[searchType]} : ${elements.length}`}</div>
      <div className="scrollWrapper">
        <table className="table-styled-training">
          <thead>
            <tr>
              {hideSelect ? null : (
                <th>
                  <input
                    ref={checkboxRef} type="checkbox" id="all" checked={selectedId.length > 0} onChange={handleAllCheckBox}
                  />
                </th>
              )}
              <th>Id</th>
              <th>Name</th>
              <th>BO</th>
            </tr>
          </thead>
          <tbody>
            {elements.map((e) => (
              <tr key={e.id}>
                {hideSelect ? null : (
                  <td>
                    <input type="checkbox" id={e.id} checked={selectedId.includes(e.id)} onChange={handleCheckBox}/>
                  </td>
                )}
                <td>{e.id}</td>
                <td>{e.name}</td>
                <td><a href={e.BOLink} target="_blank" rel="noreferrer">Lien</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TrainingManager;
