import React from "react";
import './styles.scss';

import API from '../../api';
import Lib from '../../Lib';

const Disconnect = () => {

    const handleClick = () => {
        API.signOut();
        window.location.assign(Lib.windowOrigin() + "/login");
    };

    return (
        <button
            className="btn btn-danger"
            onClick={handleClick}
            type="button">Logout
        </button>
    )
};

export default Disconnect;