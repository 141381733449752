import React from "react";
import './styles.scss';

const CreateDocuments = ({selectField, selector, setUploadFile, selectorUpdate}) => {
    const handleChange = (ev) => {
        if (ev.target.name === 'upload') {
            selectorUpdate(selector);
            const reader = new FileReader();
            let name = ev.target.files[0].name;
            reader.onload = () => {
                console.log(name, "file name");
                setUploadFile({ name: name, prefix: selector, file: reader.result });
            };
            reader.readAsDataURL(ev.target.files[0]);
        }
        if (ev.target.name === 'select') {
            selectorUpdate(ev.target.value);
        }
    };

    return (<div className="modal-body">
        <select  name={"select"} value={selector} onChange={handleChange}>
        {selectField.map(
            (el, key) => {
                return (<option key={key} value={el.name}>{el.value}</option>);
            })
        }
        </select>
        <input type={'file'} name="upload" accept="image/png, image/jpeg" onChange={handleChange} />
    </div>);
}

export default CreateDocuments;
