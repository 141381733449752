import React, {useEffect, useState} from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import API from "../../api";

import "./styles.scss";


/**
 * Display price from cents number
 * @param {string|number} price in cents value (1 => 0.01)
 * @param {string} currencyDisplay suffix string
 * @return {string} display value
 */
export function displayPrice(price, currencyDisplay = ' €') {
  const tabPrice = `${price}`.padStart(3, '0').split('');
  tabPrice.splice(tabPrice.length - 2, 0, ',');
  return tabPrice.join('') + currencyDisplay;
}

function ShopCommandTable({onCreationRefund}){
  const [clubShopCommand, clubShopCommandSet] = useState(null);

  const fetchShopCommand = () => {
    API.searchSopCommand().then((results) => {
      const shopCommands = results.body;
      const clubMap = {};
      shopCommands.forEach((sc) => {
        const period = sc.purchaseDate.substring(0,7);
        if(!clubMap.hasOwnProperty(sc.club.id)){
          clubMap[sc.club.id] = {
            clubId: sc.club.id,
            clubName: sc.club.name,
            clubMargin: sc.club.shopMargin,
            purchasePeriodMin: period,
            purchasePeriodMax: period,
            sum: 0,
          };
        }
        clubMap[sc.club.id].sum += sc.price;
        if(clubMap[sc.club.id].purchasePeriodMin > period) {
          clubMap[sc.club.id].purchasePeriodMin = period;
        } else if(clubMap[sc.club.id].purchasePeriodMax < period) {
          clubMap[sc.club.id].purchasePeriodMax = period;
        }
      });
      const fetchedClubShopCommand = Object.keys(clubMap).map((clubId) => ({
        ...clubMap[clubId], sum: displayPrice(clubMap[clubId].sum),
        marginSum: displayPrice(Math.floor(clubMap[clubId].sum * (100 - clubMap[clubId].clubMargin) / 100)),
      })).sort((a, b) => (a.clubName.localeCompare(b.clubName)));
      clubShopCommandSet(fetchedClubShopCommand);
    });
  };

  useEffect(fetchShopCommand, []);

  if (!Array.isArray(clubShopCommand)) {
    return (
      <div className="loader-wrapper">
        <PropagateLoader
          size={50}
          color={"#17a2b8"}
        />
      </div>
    );
  }

  return (
    <div className="table-container">
      <button
        type="button" className="btn btn-lg btn-info"
        onClick={ () => onCreationRefund(clubShopCommand.map((csc) => (csc.clubId)))}
      >
        Créer tous les virements
      </button>
      <table className="table-styled">
        <thead>
        <tr>
          <th>Club Id</th>
          <th>Club Name</th>
          <th>Période</th>
          <th>Marge du club %</th>
          <th>Somme Total</th>
          <th>Marge à rembourser</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {clubShopCommand.map((csc) => (
          <tr key={csc.clubId}>
            <td>{csc.clubId}</td>
            <td>{csc.clubName}</td>
            <td>{`${csc.purchasePeriodMin} à ${csc.purchasePeriodMax}`}</td>
            <td>{csc.clubMargin}</td>
            <td>{csc.sum}</td>
            <td>{csc.marginSum}</td>
            <td>
              <button type="button" className="btn btn-info" onClick={ () => onCreationRefund([csc.clubId]) }>Créer le virement</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default ShopCommandTable;
